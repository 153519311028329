import { DiscountCalculationType, Cart } from '@fe/types/allplants'

const calculateDiscountedDelivery = (cart: Cart, deliveryCost: number) => {
  const discount = cart.discountCodes?.[0]
  const cost = deliveryCost

  if (!discount) {
    return {
      cost,
      discount: 0,
      total: cost,
    }
  }

  if (discount.discountType === DiscountCalculationType.FixedAmount) {
    const remainingDiscountValue = Math.max(
      discount.amount - (cart.totalDiscount || 0),
      0
    )
    const discountedDeliveryCharge = Math.max(cost - remainingDiscountValue, 0)
    const total = Math.round(discountedDeliveryCharge)
    return {
      cost,
      total,
      discount: cost - total,
    }
  }
  if (discount.discountType === DiscountCalculationType.Percentage) {
    const discountPercentage = Math.max((100 - discount.amount) / 100, 0)
    const total = Math.round(cost * discountPercentage)
    return {
      cost,
      total,
      discount: cost - total,
    }
  }
  throw Error(
    `We do not know how to apply a delivery discount of ${discount.discountType}`
  )
}

export const calculateSubtotal = (cart: Cart, deliveryCost: number) => {
  const { subtotalPrice = 0 } = cart
  return deliveryCost === 0 ? subtotalPrice : subtotalPrice - deliveryCost
}

export const calculateOrderValues = (cart: Cart, deliveryCost?: number) => {
  const discount = cart.discountCodes?.[0]

  const delivery = calculateDiscountedDelivery(cart, deliveryCost || 0)

  const total = cart.totalPrice || 0
  const subTotal = calculateSubtotal(cart, deliveryCost || 0)

  return {
    discount,
    totalDiscount: (cart.totalDiscount || 0) + delivery.discount,
    /** The cost of delivery */
    deliveryCost: delivery.cost,
    subTotal,
    total,
  }
}
