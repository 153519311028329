/* eslint-disable camelcase */
import camelcase from 'camelcase-keys'
import { fill, flatMap } from 'lodash'

import {
  StockStatus,
  OrderFrequency,
  CartItem,
  Cart,
  ProductType,
} from '@fe/types/allplants'

type TOrderItem = Pick<CartItem, 'productType' | 'quantity' | 'variantId'>

export function getCountForProductType(
  items: Array<TOrderItem>,
  productType: ProductType
) {
  if (!items) return 0
  return items.reduce((count, item) => {
    if (item?.productType === productType) {
      const newCount = count + (item?.quantity || 0)
      return newCount
    }
    return count
  }, 0)
}

export function getCountForAllItems(items: Array<TOrderItem>) {
  if (!items) return 0
  return items.reduce((count, item) => count + (item?.quantity || 0), 0)
}

export function getCountForMeals(items: Array<TOrderItem>) {
  const mainMealsCount = getCountForProductType(items, 'MEAL')
  const pizzaCount = getCountForProductType(items, 'PIZZA')
  return mainMealsCount + pizzaCount
}

export function getCountForExtras(items: Array<TOrderItem>) {
  const treatCount = getCountForProductType(items, 'TREAT')
  const smoothieCount = getCountForProductType(items, 'SMOOTHIE')
  const breakfastCount = getCountForProductType(items, 'BREAKFAST')
  const sideCount = getCountForProductType(items, 'SIDE')
  return treatCount + breakfastCount + smoothieCount + sideCount
}

export function getCountForOther(items: Array<TOrderItem>) {
  const allItemCount = getCountForAllItems(items)
  const mealCount = getCountForMeals(items)
  const extrasCount = getCountForExtras(items)
  return allItemCount - mealCount - extrasCount
}
export function getVariantIds(items: Array<TOrderItem>) {
  return items!
    .map(({ quantity, variantId }) =>
      quantity === 1 ? variantId : fill(Array(quantity), variantId)
    )
    .join(',')
}

const getFrequencyNo = (frequency: string) => {
  switch (frequency) {
    case OrderFrequency.EightWeeks:
      return 8
    case OrderFrequency.SevenWeeks:
      return 7
    case OrderFrequency.SixWeeks:
      return 6
    case OrderFrequency.FiveWeeks:
      return 5
    case OrderFrequency.FourWeeks:
      return 4
    case OrderFrequency.ThreeWeeks:
      return 3
    case OrderFrequency.TwoWeeks:
      return 2
    case OrderFrequency.OneWeek:
      return 1
    default:
      return 0
  }
}

const getStockStatus = (variant): StockStatus => {
  if (!variant) return StockStatus.Unknown
  if (variant.stock_status === 'SOLD_OUT') return StockStatus.SoldOut
  if (variant.stock_status === 'LOW') return StockStatus.Low
  return StockStatus.Fine
}

export const getPortionSizeNumberAsString = (
  product,
  productVariant
): string => {
  if (product?.dish_type?.includes('pies_and_bakes')) return '2 - 4'
  switch (product?.product_type) {
    case 'BREAKFAST':
    case 'SMOOTHIE':
    case 'TREAT':
      return '1'
    case 'SIDE':
      return '2'
    case 'TRAYBAKE':
      return '4'
    default:
      return productVariant?.portion_size === 'SINGLE_PORTION' ? '1' : '2'
  }
}

export function formatCartResponse(cartData, products): Cart {
  const variants = flatMap(products, (p) => p.variants)
  const response = { ...cartData } as unknown as Writeable<Cart>

  response.raw = JSON.stringify(cartData)

  const firstItem = cartData.items?.[0]
  response.shippingFrequency = getFrequencyNo(cartData.frequency || 0)
  const shippingItem = cartData.items?.find((i) => i.sku_code === 'PREMSHP')
  response.shippingPrice = shippingItem ? shippingItem.price : 0

  response.skus = cartData.items
    ?.map((i) => i.sku_code)
    .filter((sku) => sku !== 'PREMSHP')

  response.hasGiftCard =
    firstItem?.is_gift_card || firstItem?.product_title === 'Gift Card'

  response.items = !cartData.items
    ? []
    : cartData.items.map((cartItem) => {
        const productVariant = variants.find(
          (variant) => variant.shopify_id === cartItem.shopify_variant_id
        )
        const singleProduct = products.find(
          (product) => product.shopify_id === cartItem.shopify_product_id
        )
        const returnItem: CartItem = {
          ...cartItem,
          id: cartItem.id || cartItem.shopify_variant_id,
          coreProductId: singleProduct?.id,
          stockStatus: getStockStatus(productVariant),
          portionSizeIntAsString: getPortionSizeNumberAsString(
            singleProduct,
            productVariant
          ),
        }
        return returnItem
      })

  return camelcase(response, { deep: true }) as Cart
}
