import * as React from 'react'

import * as Yup from 'yup'

import * as e from '@fe/components/Elements'
import * as Form from '@fe/components/Form'
import useCart from '@fe/providers/cart'
import { trackClick } from '@fe/services/analytics'
import getErrorMessageByErrorCode from '@fe/utils/errors'
import wait from '@fe/utils/wait'

import * as c from './commonComponents'
import { useDiscountManager } from './manager'

const DiscountFormSchema = Yup.object().shape({
  code: Yup.string().ensure(),
})

export function ApplyDiscountLinks() {
  const { setIsDiscountCodeOpen } = useDiscountManager()

  return (
    <>
      <e.Layout.Row>
        <c.DiscountLink
          data-testid='discount-link'
          onClick={() => {
            setIsDiscountCodeOpen(true)
            void trackClick('discount.open')
          }}
        >
          discount code?
        </c.DiscountLink>
        <c.MentionMe
          data-testid='mention-me-link'
          onClick={() => void trackClick('mentionMe.open')}
          situation='checkout'
        />
      </e.Layout.Row>
    </>
  )
}

function EditDiscountLinks() {
  const { setIsDiscountCodeOpen } = useDiscountManager()

  return (
    <>
      <e.Layout.Row>
        <c.DiscountLink
          onClick={() => {
            setIsDiscountCodeOpen(true)
            void trackClick('discount.open')
          }}
        >
          change code
        </c.DiscountLink>
      </e.Layout.Row>
    </>
  )
}

export function EditingDiscount({ token }: { token: string }) {
  const { cart } = useCart()

  const discountInputRef = React.useRef<HTMLInputElement | null>(null)
  const {
    isDiscountCodeOpen,
    successMessage,
    setIsDiscountCodeOpen,
    isTryingDiscount,
    tryApplyDiscount,
    tryRemoveDiscount,
    appliedDiscount,
    setSuccessMessage,
  } = useDiscountManager()

  const focusDiscountInput = React.useCallback(async () => {
    await wait(50)
    // eslint-disable-next-line no-unused-expressions
    discountInputRef.current?.focus()
  }, [discountInputRef])

  const removeDiscount = async (form) => {
    await tryRemoveDiscount(token, cart?.id)
    setSuccessMessage('Discount removed successfully')
    form.resetForm()
    form.setFieldValue('code', '')
  }

  const handleSubmit = async (values, form): Promise<void> => {
    try {
      await tryApplyDiscount(token, values.code, cart?.id)
      form.setFieldError('code', '')
      form.setFieldValue('code', '')
      setSuccessMessage('Discount applied successfully')
    } catch (err) {
      const errorCode = err.response?.data?.errors?.[0]?.error_code
      const errorMessage = getErrorMessageByErrorCode(errorCode)
      form.setFieldError('code', errorMessage)
      setSuccessMessage('')
    }
  }

  React.useEffect(() => {
    if (isDiscountCodeOpen) {
      void focusDiscountInput()
    }
  }, [focusDiscountInput, isDiscountCodeOpen])

  return (
    <Form.Container
      initialValues={{ code: appliedDiscount?.code }}
      onSubmit={handleSubmit}
      validationSchema={DiscountFormSchema}
    >
      {(form) => (
        <>
          <e.Layout.Row>
            <c.DiscountLink
              onClick={() => {
                setIsDiscountCodeOpen(false)
                void trackClick('discount.close')
              }}
              type='button'
            >
              close
            </c.DiscountLink>
            {appliedDiscount?.code && (
              <c.DiscountLink
                data-testid='remove-link'
                onClick={() => removeDiscount(form)}
                type='button'
              >
                remove code
              </c.DiscountLink>
            )}
          </e.Layout.Row>
          <c.DiscountInputContainer>
            <Form.Input
              {...(successMessage && { 'aria-describedby': 'status-message' })}
              autoFocus
              button={
                <Form.InputButton
                  disabled={!(form.isValid && form.values.code)}
                  name='apply'
                  onClick={() => {
                    void trackClick('discount.submit')
                  }}
                  type='submit'
                >
                  Apply
                </Form.InputButton>
              }
              isLoading={isTryingDiscount}
              label='Discount Code'
              name='code'
              placeholder='your code'
              refLink={discountInputRef}
            />
            {successMessage && (
              <c.SuccessMessage id='status-message'>
                {successMessage}
              </c.SuccessMessage>
            )}
          </c.DiscountInputContainer>
        </>
      )}
    </Form.Container>
  )
}

export default function DiscountInput({ token }: { token: string }) {
  const { isDiscountCodeOpen, appliedDiscount } = useDiscountManager()

  // eslint-disable-next-line no-nested-ternary
  return !isDiscountCodeOpen ? (
    !appliedDiscount ? (
      <ApplyDiscountLinks />
    ) : (
      <EditDiscountLinks />
    )
  ) : (
    <EditingDiscount token={token} />
  )
}
