/* eslint-disable camelcase */
import { addData, sendEvent } from './gtm'

/**
 * This library sends events through google tag manager
 * @method Track methods are useful for analytics and will be
 * automatically passed on to the analytics platforms (e.g. Amplitude)
 * with the correct name and data
 */

export const trackEvent = (
  name: string,
  data: any = {},
  permanentData: any = {}
) => {
  void addData({ log_data: undefined }) // reset data
  return sendEvent('log', {
    log_name: name,
    log_data: data,
    log_perm_data: permanentData,
  })
}

export const trackClick = (name: string, data: any = null) =>
  trackEvent('button.click', {
    ...data,
    name,
  })
export const trackTooltip = (name: string, data: any = null) =>
  trackEvent('tooltip.show', {
    ...data,
    name,
  })
export const trackSelect = (type: string, name: string, data: any = null) =>
  trackEvent(`select.${type}`, {
    ...data,
    name,
  })
export const setUserId = (id: any) => {
  // Amplitude requires we must always send ID as minimum 5 characters
  const idTo5chars = id.toLocaleString('en-GB', {
    minimumIntegerDigits: 5,
    useGrouping: false,
  })
  sendEvent('user.id', {
    user_id: idTo5chars,
  })
}
export const setUserData = (data: any) =>
  sendEvent('user.data', {
    user_data: data,
  })
type ErrorLevel = 'critical' | 'error' | 'warning' | 'info'
export const trackError = (data: any, level: ErrorLevel = 'error') =>
  trackEvent(level, data)
