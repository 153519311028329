import * as React from 'react'

const context = React.createContext<{
  deviceInfo?: IUAParser.IResult
}>(undefined as any)

export function Provider({ children }: { children: React.ReactNode }) {
  const [deviceInfo, setDeviceInfo] = React.useState<IUAParser.IResult>(
    {} as any
  )
  React.useEffect(() => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const parser = require('ua-parser-js')
    setDeviceInfo(parser())
  }, [])

  return (
    <context.Provider
      value={{
        deviceInfo,
      }}
    >
      {children}
    </context.Provider>
  )
}

export default function useFeatureDetection() {
  const featureDetection = React.useContext(context)
  return featureDetection
}
