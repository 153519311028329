// Default theme

// ----------------------------------------------------------------------------
// IMPORTS

/* Local */
import { css } from 'styled-components'

import { rhizTheme } from '../rhiz'

import media from './media'

// ----------------------------------------------------------------------------

const font = {
  sans: "'Source Sans Pro', sans-serif",
  allplants: 'stolzl, sans-serif',
  header: 'recent-grotesk, sans-serif',
}
const depth = {
  back: -9999,
  background: -99,
  behind: -9,
  neutral: 0,
  infront: 9,
  foreground: 99,
  front: 9999,
}
const brandColors = {
  allplantsYellow: '#fdd902',
  aubergine: '#5e4563',
  blueberry: '#213852',
  kale: '#05574A',
  mushroom: '#E8E3D9',
  pumpkin: '#DE613D',
  strawbMilkshake: '#EBBAB2',
}
const color = {
  ...brandColors,
  sunrise: brandColors.allplantsYellow,
  parsnip: '#fff2bd',
  raspberry: '#cc3d3d',
  grapefruit: '#fa7070',
  pistachio: '#99cc99',
  apricot: '#ffc400',
  // Other
  page: '#ffffff',
  overlay: 'rgba(0,0,0,0.6)',
  background: '#f7f7f7',
  backgroundGrey: '#F3F3F2',
  backgroundLightGrey: '#F3F3F2',
  backgroundActive: '#e9e9e9',
  lightText: '#747678',
  text: '#26292c',
  accent: '#cbcccd',
  accentComplementary: '#26292c',
  border: '#cbcccd',
  borderLight: '#e5e5e5',
  primary: brandColors.allplantsYellow,
  primaryComplementary: 'black',
  orange: '#FFA500',
  yellow: brandColors.allplantsYellow,
  lightYellow: '#FCEFCD',
  disabled: '#898B8C',
  grey: '#aeafb0',
  dark: '#202225',
  darkComplementary: '#404346',
  faint: '#b9babb',
  red: '#f94839',
  redComplementary: '#fee9e7',
  infoBlue: '#bad7f7',
  infoBorderBlue: '#4372A7',
  lightRed: '#e35535',
  lightGrey: '#CFD0CB',
  lighterGrey: '#E7E7E5',
  lightGreen: '#439487',
  successGreen: '#6cdea6',
  darkGrey: '#666666',
  lighterGreen: '#99cc99',
  paleYellow: '#fef8e5',
  black: '#000000',
  white: '#FFFFFF',
  button: {
    default: '#000000',
    active: brandColors.allplantsYellow,
    disabled: '#CFD0CB',
    hover: '#fadb7e73',
  },
  link: {
    default: '#111111',
  },
  input: {
    default: '#111111',
    active: '#0D67DE',
    hover: '#0D67DE',
    focused: '#0D67DE',
    complete: '#33557E',
    disabled: '#898B8C',
    optionHover: '#007eff38',
    text: '#747678',
    activeText: '#26292c',
    placeholder: '#A0A098',
  },
}
const easing = {
  easeInCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeInQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
  easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  easeInOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  easeInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
}
const transition = {
  defaultTime: '0.4s',
  default: `all 0.4s ${easing.easeOutCubic}`,
  quickTime: '0.2s',
  quick: `all 0.2s ${easing.easeOutCubic}`,
  offset: '100px',
  fadeInDown: 'fadeInDown .6s ease 1s 1 forwards;',
}
const layout = {
  width: '800px',
  maxWidth: '1024px',
  smallMaxWidth: '640px',
  centerBlockMaxWidth: '640px',
  padding: '16px 24px',
  spacingHorizontal: '24px',
  spacingVertical: '16px',
  spacingHorizontalVal: 20,
  spacingVerticalVal: 30,
  paddingResizeCss: css`
    padding: 8px 16px;
    ${media.phablet`
      padding: 12px 20px;
    `}
    ${media.desktop`
      padding: 16px 24px;
    `}
  `,
  margin: '16px',
  marginResizeCss: css`
    margin: 8px 16px;
    ${media.phablet`
      margin: 12px 20px;
    `}
    ${media.desktop`
      margin: 16px 20px;
    `}
  `,
  letterSpacingSmall: '0.5px',
  letterSpacingMed: '0.75px',
  letterSpacingLarge: '1.4px',
}
const other = {
  border: `1px solid ${color.border}`,
  borderRadius: '6px',
  borderLight: `1px solid ${color.borderLight}`,
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  boxShadowStrong: '0 2px 4px 0 rgba(0,0,0,0.2)',
  clearfix: css`
    :after {
      content: '';
      display: table;
      clear: both;
    }
  `,
  icon: css`
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 1;
    transition: ${transition.default};
    &:hover {
      opacity: 0.6;
    }
  `,
}

/**
 * This is the correct theme name (plural) for a styled components theme
 */
const colors = {
  colors: {
    allplantsYellow: '#FDD902',
    ...rhizTheme.colors,
  },
}

const theme = {
  media,
  font,
  depth,
  color,
  easing,
  transition,
  layout,
  other,
  ...rhizTheme,
  ...colors,
  name: 'DEFAULT',
}

export default theme
