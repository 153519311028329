import * as React from 'react'

export default function Cross(props) {
  return (
    <svg
      {...props}
      version='1.1'
      viewBox='0 0 30 31'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        fill='none'
        fillRule='evenodd'
        id='Sign-Up'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fill='currentColor'
          fillRule='nonzero'
          id='icon/close'
          transform='translate(-9.000000, -8.218750)'
        >
          <path
            d='M28.0901153,23.8474746 L38.2044603,33.9618196 C38.5196598,34.2770191 38.6011399,34.4241368 38.6547453,34.6009959 C38.7083506,34.777855 38.7083506,34.9587248 38.6547453,35.1355839 C38.6011399,35.312443 38.5196598,35.4595608 38.2044603,35.7747602 L35.7747602,38.2044603 C35.4595608,38.5196598 35.312443,38.6011399 35.1355839,38.6547453 C34.9587248,38.7083506 34.777855,38.7083506 34.6009959,38.6547453 C34.4241368,38.6011399 34.2770191,38.5196598 33.9618196,38.2044603 L23.8474746,28.0901153 L13.7331297,38.2044603 C13.4179302,38.5196598 13.2708124,38.6011399 13.0939534,38.6547453 C12.9170943,38.7083506 12.7362244,38.7083506 12.5593654,38.6547453 C12.3825063,38.6011399 12.2353885,38.5196598 11.9201891,38.2044603 L9.49048898,35.7747602 C9.1752895,35.4595608 9.09380937,35.312443 9.04020401,35.1355839 C8.98659866,34.9587248 8.98659866,34.777855 9.04020401,34.6009959 C9.09380937,34.4241368 9.1752895,34.2770191 9.49048898,33.9618196 L19.604834,23.8474746 L9.49048898,13.7331297 C9.1752895,13.4179302 9.09380937,13.2708124 9.04020401,13.0939534 C8.98659866,12.9170943 8.98659866,12.7362244 9.04020401,12.5593654 C9.09380937,12.3825063 9.1752895,12.2353885 9.49048898,11.9201891 L11.9201891,9.49048898 C12.2353885,9.1752895 12.3825063,9.09380937 12.5593654,9.04020401 C12.7362244,8.98659866 12.9170943,8.98659866 13.0939534,9.04020401 C13.2708124,9.09380937 13.4179302,9.1752895 13.7331297,9.49048898 L23.8474746,19.604834 L33.9618196,9.49048898 C34.2770191,9.1752895 34.4241368,9.09380937 34.6009959,9.04020401 C34.777855,8.98659866 34.9587248,8.98659866 35.1355839,9.04020401 C35.312443,9.09380937 35.4595608,9.1752895 35.7747602,9.49048898 L38.2044603,11.9201891 C38.5196598,12.2353885 38.6011399,12.3825063 38.6547453,12.5593654 C38.7083506,12.7362244 38.7083506,12.9170943 38.6547453,13.0939534 C38.6011399,13.2708124 38.5196598,13.4179302 38.2044603,13.7331297 L28.0901153,23.8474746 Z'
            id='Combined-Shape'
          />
        </g>
      </g>
    </svg>
  )
}
