/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'

import {
  Tooltip as TippyTooltip,
  TooltipProps as TippyTooltipProps,
} from 'react-tippy'

import styled, { defaults, ThemeProvider } from '@fe/styles'

// eslint-disable-next-line no-unused-expressions
const TooltipContainer = styled.span`
  .tippy-tooltip {
    font-family: ${defaults.font.allplants};
    box-shadow: ${defaults.other.boxShadowStrong};
    font-weight: 600;
    padding: 8px;
    border-radius: ${defaults.other.borderRadius};
  }
`
export interface TooltipProps extends TippyTooltipProps {
  onHide?: () => void
  onShow?: () => void
  onShown?: () => void
}

const Tooltip: React.FC<TooltipProps> = ({
  open,
  onHide,
  onShow,
  onShown,
  children,
  html,
  title,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(open || false)

  const internalOnShow = (willOpen: boolean) => {
    if (open === undefined) {
      setIsOpen(willOpen)
    }
    // eslint-disable-next-line no-unused-expressions
    willOpen ? onShow?.() : onHide?.()
  }

  return (
    <TooltipContainer>
      <TippyTooltip
        animateFill={false}
        arrow
        duration={200}
        html={
          html ? (
            <ThemeProvider theme={defaults}>{html}</ThemeProvider>
          ) : (
            <span>{title}</span>
          )
        }
        onHide={() => internalOnShow(false)}
        onShow={() => internalOnShow(true)}
        onShown={onShown}
        open={open === undefined ? isOpen : open}
        theme='light'
        {...rest}
      >
        {children}
      </TippyTooltip>
    </TooltipContainer>
  )
}

export default Tooltip
