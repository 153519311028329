/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'

import { connect } from 'formik'

import styled from '@fe/styles'

import { InputContainer, InputTextCss } from './components'
import InputWrapper from './inputWrapper'

const FakeInput = styled.div`
  ${InputTextCss}
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

class InputBlank<T> extends InputWrapper<T> {
  protected renderInput = () => {
    const {
      isValid,
      disabled,
      refLink,
      getErrorMessage,
      label,
      placeholder,
      iconEnd,
      onChange,
      value,
      // Take this off rest,
      ...rest
    } = this.props

    return (
      <InputContainer disabled={!!disabled} isValid>
        <FakeInput
          ref={refLink}
          isShowingLabel={!this.isEmpty}
          isValid
          placeholder={placeholder === undefined ? label : placeholder}
          {...rest}
        >
          {value}
        </FakeInput>
      </InputContainer>
    )
  }
}

export default connect(InputBlank)
