import FullLogo from '@fe/components/images/allplants/allplants-logo'
import FacebookLogo from '@fe/components/logos/social/Facebook'
import InstagramLogo from '@fe/components/logos/social/Instagram'
import TwitterLogo from '@fe/components/logos/social/Twitter'
import styled, { css } from '@fe/styles'

const SocialIcon = css`
  height: 20px;
  width: 20px;
  margin: 0 10px;
`
export const Facebook = styled(FacebookLogo)`
  ${SocialIcon}
`
export const Twitter = styled(TwitterLogo)`
  ${SocialIcon}
`
export const Instagram = styled(InstagramLogo)`
  ${SocialIcon}
`

export { FullLogo }
