import useCart from '@fe/providers/cart'

import { calculateOrderValues } from './calculateOrderValues'

export default function useOrderSummaryService(): ReturnType<
  typeof calculateOrderValues
> | null {
  const { cart } = useCart()

  const charge = cart?.items.find(
    ({ productType }) => productType === 'DELIVERY'
  )

  if (cart) {
    return calculateOrderValues(cart, charge?.price || 0)
  }

  return null
}
