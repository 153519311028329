import * as React from 'react'

import { connect } from 'formik'
import ReactSelect from 'react-select'

import InputWrapper from './inputWrapper'
import { IInput } from './types'

interface ISelectProps<T> {
  options: Array<T>
  onSelected?: (option?: T) => void
}
interface IOptionBase {
  value: string
  label: string
}
type TSelect<T = any> = Omit<IInput, 'label'> &
  React.HTMLProps<HTMLInputElement> &
  ISelectProps<T>

class SelectComponent<T extends IOptionBase> extends InputWrapper<
  T & ISelectProps<T>
> {
  protected renderInput = () => {
    const { onSelected, options, placeholder } = this.props

    return (
      <ReactSelect<T>
        onChange={(option: T) => {
          this.props.formik.setFieldValue(this.name, option.value)
          void onSelected?.(option)
        }}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (provided) => ({
            ...provided,
            borderWidth: 2,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 99999,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: '#FFDB66',
            primary25: '#FFDB66',
            primary: 'black',
          },
          spacing: {
            ...theme.spacing,
            controlHeight: 52,
          },
        })}
      />
    )
  }
}

export default connect(SelectComponent) as React.SFC<TSelect>
