import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'

import { useUserContext } from '@fe/providers/userProvider'
import { trackEvent } from '@fe/services/analytics'
import getCookieManager from '@fe/utils/cookies'
import poll from '@fe/utils/poll'

export type ModalType = 'login' | 'forgot' | 'reset' | 'account'

export default function useAuthService() {
  const cookie = getCookieManager()
  const { query, push } = useRouter()
  const { firstName, lastName, email } = useUserContext()

  const isLoggedIn =
    !!cookie.get('session_hp') && !!firstName && !!lastName && !!email
  const userDetails = {
    firstName,
    lastName,
    email,
    customerId: cookie.get('session_customer_id'),
  }

  return {
    isLoggedIn,
    userDetails,
    authModal: query && query.auth,
    waitForSessionSet: async () => {
      try {
        await poll(() => !!cookie.get('session_hp'), 100, 6000)
      } catch (e) {
        Sentry.addBreadcrumb({
          data: {
            session_hp: cookie.get('session_hp'),
          },
          category: 'auth',
          message: 'session_hp cookie not set',
          level: Sentry.Severity.Info,
        })
        throw new Error('Login cookie failed to set within timeout')
      }
    },
    openAuthModal: (type?: ModalType) => {
      const newUrl = new URL(window.location.href)
      const typeWas = query.auth

      if (typeWas === 'reset' && type !== 'reset') {
        newUrl.searchParams.delete('email')
        newUrl.searchParams.delete('token')
      }

      if (type) {
        newUrl.searchParams.set('auth', type)
        void trackEvent('auth.open', { form: type })
      } else {
        newUrl.searchParams.delete('auth')
        void trackEvent('auth.close')
      }

      void push(newUrl)
    },
  }
}
