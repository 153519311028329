// eslint-disable-next-line import/prefer-default-export
export const fontWeights = {
  // Stolzl (body)
  book: 200, // footer
  regular: 400,
  medium: 500, // homepage hero

  // Shared (headings / body)
  bold: 700,

  // Recent Grotesk (headings)
  black: 800,
  ultra: 900,
}
