export default function MsWallet(props) {
  return (
    <svg
      enableBackground='new 0 0 265.087 74.992'
      height='74.992px'
      id='Layer_1'
      version='1.1'
      viewBox='0 0 265.087 74.992'
      width='265.087px'
      x='0px'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlSpace='preserve'
      y='0px'
      {...props}
    >
      <polygon
        fill='#737474'
        points='128.841,44.021 134.338,44.021 126.071,74.34 119.919,74.34 113.83,51.464 113.703,51.464 
	107.614,74.34 101.335,74.34 92.878,44.021 98.629,44.021 104.527,67.998 104.654,67.998 111.251,44.021 116.874,44.021 
	123.027,67.955 123.154,67.955 '
      />
      <g>
        <defs>
          <rect
            height='215.992'
            id='SVGID_1_'
            width='408.869'
            x='-71.832'
            y='-69.654'
          />
        </defs>
        <clipPath id='SVGID_2_'>
          <use overflow='visible' xlinkHref='#SVGID_1_' />
        </clipPath>
        <path
          clipPath='url(#SVGID_2_)'
          d='M151.497,60.725c0,3.58,0.003,6.402,0.012,8.467
		c0.007,2.065,0.115,3.782,0.327,5.148h-5.096l-0.254-2.325h-0.084c-0.648,0.874-1.498,1.54-2.548,1.998s-2.372,0.687-3.964,0.687
		c-1.917,0-3.521-0.581-4.811-1.744s-1.935-2.73-1.935-4.704c0-2.113,0.828-3.809,2.485-5.085c1.655-1.274,4.253-2.132,7.791-2.568
		l3.107-0.38c0-1.438-0.339-2.461-1.015-3.066c-0.677-0.606-1.692-0.916-3.045-0.93c-1.466,0-2.854,0.222-4.165,0.666
		c-1.312,0.443-2.319,0.969-3.023,1.574v-4.673c0.704-0.38,1.797-0.764,3.277-1.151c1.479-0.388,3.037-0.581,4.672-0.581
		c2.706,0,4.761,0.747,6.163,2.24C150.797,55.791,151.497,57.934,151.497,60.725 M144.245,63.959
		c-2.255,0.269-3.845,0.705-4.768,1.312c-0.923,0.605-1.384,1.438-1.384,2.495c0,0.901,0.271,1.621,0.813,2.155
		c0.542,0.536,1.363,0.805,2.463,0.805c1.593,0,2.854-0.49,3.784-1.471c0.93-0.979,1.403-2.209,1.417-3.689v-1.881L144.245,63.959z'
          fill='#737474'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#737474'
          height='32.052'
          width='5.117'
          x='156.296'
          y='42.288'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#737474'
          height='32.052'
          width='5.116'
          x='166.357'
          y='42.288'
        />
        <path
          clipPath='url(#SVGID_2_)'
          d='M193.251,68.864v4.207c-0.846,0.536-1.951,0.97-3.318,1.301
		c-1.368,0.331-2.827,0.496-4.377,0.496c-3.313,0-5.889-0.979-7.728-2.938c-1.84-1.959-2.76-4.688-2.76-8.183
		c0-3.369,0.983-6.142,2.949-8.319c1.967-2.179,4.458-3.268,7.475-3.268c3.002,0,5.338,0.92,7.008,2.759
		c1.672,1.841,2.507,4.381,2.507,7.623v2.474h-14.886c0.227,2.185,0.928,3.711,2.104,4.577s2.696,1.301,4.556,1.301
		c1.228,0,2.396-0.188,3.51-0.561C191.404,69.96,192.392,69.471,193.251,68.864 M189.975,61.274c0-1.691-0.393-2.981-1.174-3.869
		c-0.782-0.888-1.886-1.332-3.309-1.332c-1.227,0-2.326,0.44-3.299,1.321c-0.972,0.881-1.621,2.174-1.945,3.88H189.975z'
          fill='#737474'
        />
        <path
          clipPath='url(#SVGID_2_)'
          d='M207.522,70.619c0.296,0,0.651-0.07,1.067-0.211
		c0.415-0.142,0.778-0.311,1.09-0.508v4.229c-0.325,0.183-0.864,0.353-1.618,0.507c-0.754,0.155-1.497,0.233-2.23,0.233
		c-2.157,0-3.773-0.574-4.852-1.724c-1.079-1.148-1.618-2.879-1.618-5.19V56.792h-3.573v-4.186h3.573v-4.885l5.138-1.564v6.449
		h5.181v4.186h-5.181v10.169c0,1.34,0.243,2.284,0.729,2.833C205.714,70.344,206.479,70.619,207.522,70.619'
          fill='#737474'
        />
        <path
          clipPath='url(#SVGID_2_)'
          d='M126.574,2.34v30.318h-5.265V8.894h-0.085l-9.408,23.764h-3.489L98.687,8.894
		h-0.063v23.764H93.76V2.34h7.548l8.711,22.475h0.126l9.198-22.475H126.574z M130.977,4.645c0-0.846,0.307-1.555,0.92-2.125
		c0.613-0.571,1.342-0.857,2.188-0.857c0.902,0,1.649,0.293,2.241,0.878s0.889,1.286,0.889,2.104c0,0.831-0.304,1.529-0.909,2.092
		c-0.606,0.564-1.347,0.846-2.221,0.846c-0.873,0-1.61-0.285-2.208-0.856C131.275,6.156,130.977,5.462,130.977,4.645
		 M136.621,32.658h-5.116V10.924h5.116V32.658z M152.141,28.937c0.761,0,1.599-0.176,2.516-0.528
		c0.916-0.353,1.761-0.818,2.537-1.396v4.757c-0.818,0.465-1.744,0.818-2.781,1.057c-1.034,0.24-2.174,0.36-3.414,0.36
		c-3.199,0-5.8-1.011-7.801-3.034c-2.002-2.022-3.003-4.605-3.003-7.749c0-3.495,1.022-6.374,3.066-8.637
		c2.044-2.262,4.939-3.393,8.689-3.393c0.958,0,1.928,0.124,2.907,0.37c0.979,0.247,1.758,0.532,2.336,0.856v4.906
		c-0.79-0.579-1.597-1.026-2.421-1.343s-1.667-0.476-2.527-0.476c-2.015,0-3.643,0.655-4.883,1.966
		c-1.241,1.311-1.86,3.08-1.86,5.307c0,2.199,0.595,3.912,1.785,5.138C148.479,28.324,150.097,28.937,152.141,28.937
		 M171.761,10.565c0.407,0,0.774,0.028,1.099,0.084c0.324,0.056,0.599,0.127,0.825,0.211v5.18c-0.269-0.197-0.659-0.384-1.173-0.56
		c-0.516-0.176-1.14-0.264-1.872-0.264c-1.254,0-2.315,0.528-3.183,1.585c-0.865,1.058-1.3,2.685-1.3,4.884v10.973h-5.117V10.924
		h5.117v3.425h0.085c0.465-1.184,1.169-2.111,2.114-2.781C169.301,10.9,170.436,10.565,171.761,10.565 M173.962,22.108
		c0-3.594,1.014-6.441,3.044-8.541c2.029-2.101,4.849-3.151,8.457-3.151c3.396,0,6.051,1.012,7.961,3.034
		c1.908,2.023,2.863,4.754,2.863,8.193c0,3.524-1.015,6.329-3.044,8.415s-4.793,3.129-8.288,3.129c-3.368,0-6.044-0.99-8.023-2.971
		C174.951,28.236,173.962,25.533,173.962,22.108 M179.289,21.939c0,2.27,0.515,4.003,1.543,5.201
		c1.029,1.199,2.502,1.797,4.42,1.797c1.86,0,3.276-0.598,4.248-1.797c0.974-1.198,1.459-2.974,1.459-5.328
		c0-2.339-0.504-4.105-1.511-5.296c-1.008-1.191-2.421-1.786-4.239-1.786c-1.875,0-3.329,0.623-4.365,1.871
		C179.807,17.848,179.289,19.628,179.289,21.939 M203.898,16.632c0,0.733,0.233,1.308,0.698,1.724
		c0.465,0.415,1.494,0.94,3.087,1.574c2.043,0.818,3.478,1.738,4.302,2.76c0.825,1.022,1.237,2.258,1.237,3.71
		c0,2.044-0.786,3.686-2.357,4.926c-1.572,1.241-3.697,1.861-6.375,1.861c-0.901,0-1.898-0.109-2.992-0.328
		c-1.092-0.218-2.019-0.497-2.779-0.835v-5.032c0.931,0.649,1.931,1.163,3.003,1.543c1.07,0.381,2.043,0.572,2.917,0.572
		c1.156,0,2.009-0.163,2.559-0.487c0.549-0.324,0.824-0.867,0.824-1.628c0-0.705-0.285-1.3-0.856-1.786
		c-0.57-0.487-1.652-1.047-3.245-1.681c-1.889-0.79-3.228-1.678-4.018-2.664c-0.789-0.987-1.184-2.241-1.184-3.764
		c0-1.959,0.778-3.569,2.336-4.831c1.558-1.261,3.577-1.892,6.058-1.892c0.761,0,1.614,0.085,2.559,0.254
		c0.944,0.169,1.733,0.388,2.367,0.655v4.863c-0.676-0.451-1.466-0.838-2.367-1.163c-0.902-0.324-1.798-0.486-2.686-0.486
		c-0.972,0-1.729,0.19-2.272,0.571C204.17,15.448,203.898,15.97,203.898,16.632 M215.421,22.108c0-3.594,1.015-6.441,3.045-8.541
		c2.028-2.101,4.848-3.151,8.457-3.151c3.396,0,6.05,1.012,7.96,3.034c1.909,2.023,2.864,4.754,2.864,8.193
		c0,3.524-1.015,6.329-3.045,8.415c-2.028,2.086-4.792,3.129-8.288,3.129c-3.368,0-6.043-0.99-8.022-2.971
		C216.41,28.236,215.421,25.533,215.421,22.108 M220.748,21.939c0,2.27,0.516,4.003,1.544,5.201
		c1.028,1.199,2.501,1.797,4.419,1.797c1.86,0,3.277-0.598,4.25-1.797c0.972-1.198,1.459-2.974,1.459-5.328
		c0-2.339-0.505-4.105-1.513-5.296s-2.421-1.786-4.239-1.786c-1.875,0-3.329,0.623-4.365,1.871
		C221.267,17.848,220.748,19.628,220.748,21.939 M254.722,15.11h-7.62v17.548h-5.18V15.11h-3.638v-4.186h3.638V7.901
		c0-2.284,0.742-4.155,2.23-5.614c1.486-1.459,3.394-2.188,5.718-2.188c0.621,0,1.17,0.032,1.65,0.095
		c0.479,0.063,0.902,0.159,1.268,0.285v4.419c-0.169-0.099-0.465-0.218-0.888-0.359c-0.423-0.141-0.909-0.212-1.458-0.212
		c-1.072,0-1.896,0.335-2.474,1.005c-0.579,0.669-0.867,1.659-0.867,2.97v2.622h7.62V6.04l5.138-1.565v6.449h5.18v4.186h-5.18
		v10.169c0,1.34,0.243,2.284,0.729,2.833c0.486,0.55,1.251,0.825,2.294,0.825c0.296,0,0.651-0.07,1.067-0.211
		s0.778-0.31,1.089-0.508v4.229c-0.324,0.183-0.863,0.352-1.617,0.507c-0.755,0.156-1.497,0.233-2.23,0.233
		c-2.157,0-3.774-0.574-4.852-1.723c-1.079-1.149-1.618-2.879-1.618-5.191V15.11z'
          fill='#737474'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#F05124'
          height='34.218'
          width='34.218'
          x='0.164'
          y='2.342'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#7EBB42'
          height='34.218'
          width='34.218'
          x='37.943'
          y='2.342'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#32A0DA'
          height='34.218'
          width='34.218'
          x='0.164'
          y='40.121'
        />
        <rect
          clipPath='url(#SVGID_2_)'
          fill='#FDB813'
          height='34.218'
          width='34.218'
          x='37.943'
          y='40.121'
        />
      </g>
    </svg>
  )
}
