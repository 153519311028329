import * as e from '@fe/components/Elements'
import styled, { css } from '@fe/styles'

import Autosuggest from './autosuggest'
import Blank from './blank'
import Checkbox from './checkbox'
import { SuffixIcon, InputButton, ErrorMessage, ErrorText } from './components'
import Container from './container'
import Date from './date'
import Errors from './errors'
import Input from './input'
import Radio, { RadioGroup } from './radio'
import Select from './select'
import Textarea from './textarea'
// import Select from './select';
import * as Utils from './utils'

const Title = styled.h3<{
  hasIcon?: boolean
}>`
  font-size: 20px;
  font-weight: bold;
  padding: 4px 0 10px 0;
  margin: 0;
  ${(p) =>
    p.hasIcon &&
    css`
      display: flex;
      justify-content: space-between;
    `}
`
const TitleInfo = styled(e.Icon.Info)`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  position: relative;
  top: 3px;
  cursor: pointer;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 16px;
`
const Cell = styled.div`
  flex: 1;
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`
const Comment = styled.div``
export {
  Container,
  Errors,
  Blank,
  Input,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  Textarea,
  Autosuggest,
  Date,
  InputButton,
  Title,
  TitleInfo,
  Row,
  Cell,
  Comment,
  ErrorMessage,
  ErrorText,
  SuffixIcon,
  Utils,
}
