import * as React from 'react'

import { Formik, Form, FormikConfig, FormikProps, FormikHelpers } from 'formik'
import { ObjectSchema } from 'yup'

import AutoFocusOnError from '@fe/components/Form/autoFocusOnError'

interface IExportedProps<T> {
  children:
    | JSX.Element
    | Array<JSX.Element>
    | ((props: FormikProps<T>) => JSX.Element)
  ref?: React.RefObject<any>
  className?: string
  onChange?: (formData: T) => void
  onFocusChanged?: (isFocused: boolean, formData: T) => void
  onBlur?: (formData: T) => void
  onInvalid?: () => void
  onValid?: () => void
  name?: string
  disableAutoFocus?: boolean
  initialValues?: T
  // Using on valid changed reduces the render cycles as it only updates the state when there is a change
  onValidChanged?: (isValid: boolean) => void
  onSubmit?: (data: T, actions: FormikHelpers<T>) => void
  validationSchema?: ObjectSchema
}
class FormContainer<T> extends React.Component<
  IExportedProps<T> & FormikProps<T> & FormikConfig<T>
> {
  private form: FormikProps<T> | undefined

  private isValid = false

  private onChange = (values) => {
    const { onChange } = this.props
    if (!onChange) return null
    onChange(values)
  }

  private reportValidationChanges = () => {
    const newIsValid = this.form?.isValid || false
    const { onValidChanged } = this.props

    if (this.form?.dirty) {
      if (this.isValid !== newIsValid) {
        this.isValid = newIsValid
        if (onValidChanged) onValidChanged(newIsValid)
      }
    }
  }

  public componentDidUpdate = () => {
    this.reportValidationChanges()
  }

  public render = () => {
    const {
      name,
      children,
      ref,
      initialValues,
      validationSchema,
      onSubmit,
      onFocusChanged,
      onBlur,
      disableAutoFocus,
      className,
    } = this.props

    return (
      <Formik
        ref={ref}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          try {
            if (onSubmit) onSubmit(values, actions)
            actions.setSubmitting(false)
          } catch (e) {
            actions.setSubmitting(false)
            throw e
          }
        }}
        validate={async (values) => {
          this.reportValidationChanges()
          this.onChange(values)
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          this.form = props
          return (
            <Form
              className={className}
              name={name}
              onBlur={() => {
                this.onChange(props.values)
                if (onFocusChanged) onFocusChanged(false, props.values)
                if (onBlur) onBlur(props.values)
              }}
              onFocus={() => {
                if (onFocusChanged) onFocusChanged(true, props.values)
              }}
            >
              {typeof children === 'function' ? children(props) : children}
              {!disableAutoFocus && <AutoFocusOnError formName={name} />}
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default FormContainer as unknown as React.SFC<IExportedProps<any>>
