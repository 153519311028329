import * as React from 'react'

export default function Account() {
  return (
    <svg
      height='17px'
      version='1.1'
      viewBox='0 0 26 34'
      width='12px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        fill='currentColor'
        fillRule='evenodd'
        id='ICONS'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fillRule='nonzero'
          id='NEW'
          transform='translate(-75.000000, -71.000000)'
        >
          <g id='icon/account' transform='translate(64.000000, 64.000000)'>
            <path
              d='M24,7 C30,7 32,11.581722 32,16 C32,19 31,22 31,22 C30,25 28.418278,28 24,28 C19.581722,28 18,25 17,22 C17,22 16,19 16,16 C16,11.5879224 18.024729,7 24,7 Z M24,30 C30,30 32.8,31 36.4004879,36.7506099 C37.024729,38 37,39.5 35,40 C32.3333333,40.6666667 28.6666667,41 24,41 C19.3333333,41 15.6666667,40.6666667 13,40 C11,39.5 11,38 11.5995121,36.7506099 C15,31 18,30 24,30 Z'
              id='Combined-Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
