import * as React from 'react'

export default function Card() {
  return (
    <svg
      height='16px'
      version='1.1'
      viewBox='0 0 48 33'
      width='24px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        fill='currentColor'
        fillRule='evenodd'
        id='ICONS'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fillRule='nonzero'
          id='NEW'
          transform='translate(-1088.000000, -72.000000)'
        >
          <g
            id='icon/credit-card'
            transform='translate(1088.000000, 64.000000)'
          >
            <path
              d='M2,23 L2,35.3722296 C2,36.6959334 2.07784319,37.0990386 2.2978918,37.5104937 C2.46010639,37.8138086 2.68619141,38.0398936 2.98950631,38.2021082 C3.40096145,38.4221568 3.80406657,38.5 5.1277704,38.5 L42.8722296,38.5 C44.1959334,38.5 44.5990386,38.4221568 45.0104937,38.2021082 C45.3138086,38.0398936 45.5398936,37.8138086 45.7021082,37.5104937 C45.9221568,37.0990386 46,36.6959334 46,35.3722296 L46,23 L2,23 Z M2,15 L46,15 L46,13.6277704 C46,12.3040666 45.9221568,11.9009614 45.7021082,11.4895063 C45.5398936,11.1861914 45.3138086,10.9601064 45.0104937,10.7978918 C44.5990386,10.5778432 44.1959334,10.5 42.8722296,10.5 L5.1277704,10.5 C3.80406657,10.5 3.40096145,10.5778432 2.98950631,10.7978918 C2.68619141,10.9601064 2.46010639,11.1861914 2.2978918,11.4895063 C2.07784319,11.9009614 2,12.3040666 2,13.6277704 L2,15 Z M5.1277704,8.5 L42.8722296,8.5 C44.6552671,8.5 45.3018396,8.68565122 45.9536914,9.03426541 C46.6055433,9.38287959 47.1171204,9.89445674 47.4657346,10.5463086 C47.8143488,11.1981604 48,11.8447329 48,13.6277704 L48,35.3722296 C48,37.1552671 47.8143488,37.8018396 47.4657346,38.4536914 C47.1171204,39.1055433 46.6055433,39.6171204 45.9536914,39.9657346 C45.3018396,40.3143488 44.6552671,40.5 42.8722296,40.5 L5.1277704,40.5 C3.34473292,40.5 2.69816044,40.3143488 2.04630859,39.9657346 C1.39445674,39.6171204 0.882879593,39.1055433 0.534265408,38.4536914 C0.185651222,37.8018396 2.18359114e-16,37.1552671 0,35.3722296 L2.76224082e-16,13.6277704 C5.78649679e-17,11.8447329 0.185651222,11.1981604 0.534265408,10.5463086 C0.882879593,9.89445674 1.39445674,9.38287959 2.04630859,9.03426541 C2.69816044,8.68565122 3.34473292,8.5 5.1277704,8.5 Z'
              id='Combined-Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
