import * as React from 'react'

import { connect, Field } from 'formik'

import * as e from '@fe/components/Elements'
import * as c from '@fe/components/Form/components'
import { InputType, InputInternalProps } from '@fe/components/Form/types'
import { trackSelect } from '@fe/services/analytics'
import styled, { css } from '@fe/styles'

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  font-family: ${(p) => p.theme.font.allplants};
`

const LabelContainer = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const CheckmarkContainer = styled.span<{
  checked: boolean
}>`
  display: inline-block;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.color.lightGrey};
  margin-right: 10px;
`
const Checkmark = styled(e.Icon.Tick)<{
  checked: boolean
}>`
  position: relative;
  right: 1px;
  padding: 2px;
  opacity: 0;
  transform: scale(0.8);
  transition: ${(p) => p.theme.transition.default};
  ${(p) =>
    p.checked &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`

const LabelText = styled.div`
  display: inline-block;
  font-size: 16px;
  text-align: left;
`
const InvisibleInput = styled(Field)`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`

class InputComponent<P> extends React.Component<
  InputType<P> & InputInternalProps<P>
> {
  protected get name(): string {
    const { name } = this.props
    return name
  }

  protected get value(): boolean {
    const {
      formik: { values },
    } = this.props
    return values[this.name]
  }

  protected get isEmpty(): boolean {
    return !this.value
  }

  protected get isValid(): boolean {
    return !!this.errorMessage
  }

  protected get isDirty(): boolean {
    const {
      formik: { touched },
    } = this.props
    return !touched[this.name]
  }

  protected get isPristine(): boolean {
    return !this.isDirty
  }

  protected get errorMessage(): string {
    const {
      formik: { errors },
    } = this.props
    return errors[this.name]
  }

  public render = () => {
    const { label, name, formik, isCheckoutForm } = this.props

    return (
      <ComponentContainer>
        <LabelContainer>
          <InvisibleInput
            name={name}
            onChange={() => {
              const newValue = !this.value
              formik.setFieldValue(name, newValue)
              void trackSelect('checkbox', name, {
                value: newValue,
              })
            }}
            type='checkbox'
            value={this.value}
          />
          <CheckmarkContainer
            checked={isCheckoutForm ? !this.value : this.value}
          >
            <Checkmark checked={isCheckoutForm ? !this.value : this.value} />
          </CheckmarkContainer>
          <LabelText>{label}</LabelText>
        </LabelContainer>
        <c.ErrorMessage name={name} />
      </ComponentContainer>
    )
  }
}
export default connect<InputType>(InputComponent)
