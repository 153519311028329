import { colors } from './foundations/colors'
import { fontSizes } from './foundations/font-sizes'
import { fontWeights } from './foundations/font-weights'
import { fonts } from './foundations/fonts'
import { sizes } from './foundations/sizes'
import { textStyles } from './foundations/text-styles'

export * from './components/text'
export * from './components/box'

// Default Theme
export const rhizTheme = {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  textStyles,
  sizes,
}
