import { FormikProps } from 'formik'
import * as safeAccess from 'safe-access'

// eslint-disable-next-line import/prefer-default-export
export const tryUpdateDefaultIfUndefinedAndUntouched = <T>(
  form: FormikProps<T>,
  propName: string,
  value: any
): boolean => {
  const safeForm = safeAccess(form)
  const currentValue = safeForm(`values.${propName}`)
  if (value === currentValue) return false

  const hasInitialValue = !!safeForm(`initialValues.${propName}`)
  const hasBeenTouched = !!safeForm(`touched.${propName}`)
  if (!hasInitialValue && !hasBeenTouched && value) {
    form.setFieldValue(propName, value)
    return true
  }
  return false
}
