export default function Warning(props) {
  return (
    <svg
      fill='none'
      height='16'
      viewBox='0 0 28 28'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14 21.1334C14.4444 21.1334 14.8056 20.9945 15.0833 20.7167C15.3611 20.439 15.5 20.0779 15.5 19.6334C15.5 19.2112 15.3611 18.8501 15.0833 18.5501C14.8056 18.2501 14.4444 18.1001 14 18.1001C13.5556 18.1001 13.1944 18.2501 12.9167 18.5501C12.6389 18.8501 12.5 19.2112 12.5 19.6334C12.5 20.0779 12.6389 20.439 12.9167 20.7167C13.1944 20.9945 13.5556 21.1334 14 21.1334ZM12.7667 15.6001H15.4333V6.83341H12.7667V15.6001ZM14 27.8001C12.0889 27.8001 10.3 27.439 8.63333 26.7167C6.96666 25.9945 5.50555 25.0056 4.25 23.7501C2.99444 22.4945 2.00555 21.0334 1.28333 19.3667C0.561108 17.7001 0.199997 15.9112 0.199997 14.0001C0.199997 12.0667 0.561108 10.2667 1.28333 8.60008C2.00555 6.93341 2.98889 5.47786 4.23333 4.23341C5.47777 2.98897 6.93889 2.00008 8.61666 1.26675C10.2944 0.533415 12.0889 0.166748 14 0.166748C15.9333 0.166748 17.7389 0.533415 19.4167 1.26675C21.0944 2.00008 22.55 2.98341 23.7833 4.21675C25.0167 5.45008 26 6.90564 26.7333 8.58341C27.4667 10.2612 27.8333 12.0667 27.8333 14.0001C27.8333 15.9112 27.4667 17.7056 26.7333 19.3834C26 21.0612 25.0111 22.5223 23.7667 23.7667C22.5222 25.0112 21.0667 25.9945 19.4 26.7167C17.7333 27.439 15.9333 27.8001 14 27.8001ZM14.0333 25.1667C17.1222 25.1667 19.75 24.0779 21.9167 21.9001C24.0833 19.7223 25.1667 17.0779 25.1667 13.9667C25.1667 10.8779 24.0833 8.25008 21.9167 6.08341C19.75 3.91675 17.1111 2.83341 14 2.83341C10.9111 2.83341 8.27777 3.91675 6.1 6.08341C3.92222 8.25008 2.83333 10.889 2.83333 14.0001C2.83333 17.089 3.92222 19.7223 6.1 21.9001C8.27777 24.0779 10.9222 25.1667 14.0333 25.1667Z'
        fill='currentColor'
      />
    </svg>
  )
}
