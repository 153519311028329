import { toast } from 'react-hot-toast'

import { Blank as BlankButton } from '@fe/components/Elements/button'
import styled, { css } from '@fe/styles'

import { Cross, Tick, Warning } from './icon'

type ToastTypes = 'default' | 'error' | 'success'

const StyledContainer = styled.div<{
  type: ToastTypes
  message: string
}>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 14px 36px 14px 16px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.grey['200']};
  color: ${(p) => p.theme.colors.black};
  position: relative;

  ${(p) =>
    p.type === 'success' &&
    css`
      background-color: ${p.theme.colors.success.main};
      color: ${p.theme.colors.success.contrastText};
    `}

  ${(p) =>
    p.type === 'error' &&
    css`
      background-color: ${p.theme.colors.error.main};
      color: ${p.theme.colors.error.contrastText};
    `}
`

const StyledCross = styled(Cross)`
  width: 11px;
  height: 11px;
`

const StyledTick = styled(Tick)`
  width: 27px;
  height: 27px;
`

const StyledWarning = styled(Warning)`
  width: 27px;
  height: 27px;
`

const StyledButton = styled(BlankButton)`
  align-self: flex-start;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 16px;
`

const handleRemoveToast = () => toast.remove()

type ToastProps = {
  message: string
  type: ToastTypes
}
export default ({ message, type = 'default' }: ToastProps) => (
  <StyledContainer type={type}>
    {type === 'success' && <StyledTick />}
    {type === 'error' && <StyledWarning />}
    {message}
    <StyledButton
      aria-label='Close message'
      onClick={handleRemoveToast}
      type='button'
    >
      <StyledCross />
    </StyledButton>
  </StyledContainer>
)
