import * as React from 'react'

export default function Arrow(props) {
  return (
    <svg
      {...props}
      version='1.1'
      viewBox='0 0 22 13'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        fill='none'
        fillRule='evenodd'
        id='Symbols'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fill='currentColor'
          fillRule='nonzero'
          id='unit/basket/checkout/desktop/closed'
          transform='translate(-973.000000, -34.000000)'
        >
          <g
            id='icon/arrowhead/down'
            transform='translate(968.000000, 24.000000)'
          >
            <path
              d='M12.458466,5.86711817 L22.1389423,15.8494006 C22.3981303,16.1166693 22.3981303,16.5499974 22.1389423,16.8172661 L12.458466,26.7995485 C12.199278,27.0668172 11.7790512,27.0668172 11.5198632,26.7995485 L9.86105768,25.1098113 C9.60186966,24.8425427 9.60186966,24.4092145 9.86105768,24.1419458 L17.4134271,16.3333333 L9.86105768,8.52472082 C9.60186966,8.25745215 9.60186966,7.82412401 9.86105768,7.55685534 L11.5198632,5.86711817 C11.7790512,5.5998495 12.199278,5.5998495 12.458466,5.86711817 Z'
              id='Path'
              transform='translate(16.000000, 16.333333) rotate(90.000000) translate(-16.000000, -16.333333) '
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
