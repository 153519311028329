/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'

import { connect } from 'formik'

import * as c from './components'
import InputWrapper from './inputWrapper'
// import withInfo from './field';
import { IInput } from './types'

class InputComponent<T> extends InputWrapper<T> {
  protected renderInput = () => {
    const {
      label,
      placeholder,
      iconEnd,
      onChange,
      refLink,
      disabled,
      // Take this off rest,
      ...rest
    } = this.props

    return (
      <c.InputContainer
        disabled={!!disabled}
        isDirty={this.isDirty}
        isFocused={this.isFocused}
        isValid={this.isValid}
        name={this.name}
      >
        <c.Input
          forwardRef={refLink}
          {...rest}
          disabled={disabled}
          id={this.name}
          isShowingLabel={!this.isEmpty}
          isValid={this.isValid}
          name={this.name}
          placeholder={placeholder === undefined ? label : placeholder}
        />
      </c.InputContainer>
    )
  }
}

export default connect(InputComponent) as React.SFC<
  IInput & React.HTMLProps<HTMLInputElement>
>
