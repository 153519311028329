import rudderStackTrack from '@fe/services/rudderstack/track'
import { Discount } from '@fe/types/allplants'

import { EVENTS } from '../constants'

export const couponEntered = (discountCode, cartId) => {
  rudderStackTrack(EVENTS.COUPON_ENTERED, {
    cart_id: cartId,
    coupon_id: discountCode,
  })
}

export const couponApplied = (discountObject: Discount[], cartId: number) => {
  const amount = discountObject?.[0]?.amount
  const code = discountObject?.[0]?.code

  rudderStackTrack(EVENTS.COUPON_APPLIED, {
    cart_id: cartId,
    coupon_id: code,
    discount: amount,
  })
}

export const couponFailed = (discountCode, cartId) => {
  rudderStackTrack(EVENTS.COUPON_FAILED, {
    cart_id: cartId,
    coupon_id: discountCode,
  })
}

export const couponRemoved = (discountObject: Discount[], cartId: number) => {
  const amount = discountObject?.[0]?.amount
  const code = discountObject?.[0]?.code

  rudderStackTrack(EVENTS.COUPON_REMOVED, {
    cart_id: cartId,
    coupon_id: code,
    discount: amount,
  })
}
