export const baseColors = {
  white: '#fff',
  black: '#000',

  yellow: {
    50: '#fff9db',
    100: '#ffedaf',
    200: '#ffe17f',
    300: '#ffd54d', // brand
    400: '#ffc91e',
    500: '#e6b006',
    600: '#b38800',
    700: '#806200',
    800: '#4e3a00',
    900: '#1d1400',
  },

  blue: {
    50: '#EAEFFF',
    100: '#BAD7F7',
    200: '#8FBCEF',
    300: '#64A1E9',
    400: '#3C86E3',
    500: '#276CC9',
    600: '#1D549D',
    700: '#133C71',
    800: '#092A50', // blueberry
    900: '#000C1B',
  },

  orange: {
    50: '#FFECDF',
    100: '#FDCFB4',
    200: '#F8A986',
    300: '#F37944', // main
    400: '#F06528',
    500: '#D74C10',
    600: '#A83A0B',
    700: '#792907',
    800: '#4A1701',
    900: '#1E0500',
  },

  red: {
    50: '#FFE9E3',
    100: '#FBC4B9',
    200: '#F29F8D',
    300: '#EB7961',
    400: '#E35535',
    500: '#E6371F', // strawberry (main)
    600: '#9E2D14',
    700: '#71200D',
    800: '#461105',
    900: '#1E0300',
  },

  green: {
    50: '#E7FDE5',
    100: '#C4F3BD',
    200: '#9FEA94',
    300: '#7AE26B',
    400: '#42D95A',
    500: '#29C053',
    600: '#1F9540',
    700: '#15795B',
    800: '#05574A', // main
    900: '#001915',
  },

  grey: {
    50: '#FFFEFE',
    100: '#F5F5F5',
    200: '#E0E0E0', // light
    300: '#A6A6A6',
    400: '#999999', // base
    500: '#737373',
    600: '#595959', // dark
    700: '#413F40',
    800: '#292526',
    900: '#120B0D',
  },
}

export const statuses = {
  success: baseColors.green[100],
  info: baseColors.blue[100],
  warning: baseColors.yellow[100],
  error: baseColors.red[300],
}

export const colorSchemes = {
  brandYellow: {
    main: baseColors.yellow[300],
    contrastText: baseColors.blue[800],
  },
  tomato: {
    main: baseColors.red[100],
    contrastText: baseColors.black,
  },
  pumpkin: {
    main: baseColors.orange[500],
    contrastText: baseColors.black,
  },
  kale: {
    main: baseColors.green[800],
    contrastText: baseColors.white,
  },
  apple: {
    main: baseColors.green[200],
    contrastText: baseColors.black,
  },
  sky: {
    main: baseColors.blue[100],
    dark: baseColors.blue[400],
    contrastText: baseColors.black,
  },
  blueberry: {
    main: baseColors.blue[800],
    contrastText: baseColors.white,
  },
  whiteAlpha: {
    main: baseColors.white,
    contrastText: baseColors.blue[800],
  },
  blackAlpha: {
    main: baseColors.black,
    contrastText: baseColors.white,
  },

  /**
   * Status colors
   * Used For Alerts, Toasts, etc...
   */

  success: {
    main: statuses.success,
    contrastText: baseColors.black,
  },
  info: {
    main: statuses.info,
    contrastText: baseColors.black,
  },
  error: {
    main: statuses.error,
    contrastText: baseColors.black,
  },
  warning: {
    main: statuses.warning,
    contrastText: baseColors.black,
  },
}

export const colorSchemeList = Object.keys(colorSchemes)
export type TColorScheme = typeof colorSchemeList[number]

export const colors = {
  ...baseColors,
  ...statuses,
  ...colorSchemes,
}
