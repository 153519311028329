// eslint-disable-next-line import/prefer-default-export
export const EVENTS = {
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  ORDER_COMPLETED: 'Order Completed',
  COUPON_APPLIED: 'Coupon Applied',
  COUPON_ENTERED: 'Coupon Entered',
  COUPON_FAILED: 'Coupon Denied',
  COUPON_REMOVED: 'Coupon Removed',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
}
