import * as React from 'react'

import theme from '@fe/styles/themes/default'
import { OrderSummary } from '@fe/types/orderSummary'
import * as format from '@fe/utils/format'

import * as c from './commonComponents'
import { useOrderSummary } from './context'

interface IRow {
  label: string
  value: number
}

export function createDeliveryRow(orderValues: OrderSummary): IRow {
  return {
    label: 'Delivery fee',
    value: orderValues.deliveryCost > 0 ? orderValues.deliveryCost : 0,
  }
}
export function createDiscountRow(orderValues: OrderSummary): IRow | undefined {
  const { discount } = orderValues
  if (!discount) return undefined
  return {
    label: discount.code,
    value: orderValues.totalDiscount,
  }
}

export function createSubtotalRow(orderValues: OrderSummary): IRow {
  return {
    label: 'Subtotal',
    value: orderValues.subTotal,
  }
}

export function createTotalRow(orderValues: OrderSummary): IRow {
  return {
    label: 'Total',
    value: orderValues.total,
  }
}

export function useOrderRows() {
  const orderValues = useOrderSummary()

  if (orderValues) {
    return {
      subtotal: createSubtotalRow(orderValues),
      delivery: createDeliveryRow(orderValues),
      discount: createDiscountRow(orderValues),
      total: createTotalRow(orderValues),
    }
  }
}

export default function OrderSummaryComponent() {
  const rows = useOrderRows()

  if (!rows) return null

  return (
    <div>
      <c.Table>
        <c.TBody>
          {rows.subtotal ? (
            <c.Row>
              <c.CellLabel>{rows.subtotal.label}</c.CellLabel>
              <c.CellValue>{format.currency(rows.subtotal.value)}</c.CellValue>
            </c.Row>
          ) : null}
          <c.Row>
            <c.CellLabel>{rows.delivery.label}</c.CellLabel>
            <c.CellValue id='delivery-price'>
              {format.currency(rows.delivery.value)}
            </c.CellValue>
          </c.Row>
          {rows.discount ? (
            <c.Row>
              <c.CellLabel style={{ color: theme.color.pumpkin }}>
                {rows.discount.label}
              </c.CellLabel>
              <c.CellValue style={{ color: theme.color.pumpkin }}>
                - {format.currency(rows.discount.value)}
              </c.CellValue>
            </c.Row>
          ) : null}
          <c.Row isTotal>
            <c.CellLabel>{rows.total.label}</c.CellLabel>
            <c.CellValue id='total-price'>
              {format.currency(rows.total.value)}
            </c.CellValue>
          </c.Row>
        </c.TBody>
      </c.Table>
    </div>
  )
}
