/**
 * Converts pixel size to rem and accepts the base as second argument. default base is 16px
 */
// eslint-disable-next-line arrow-body-style
export const remCalc = (px: number, base = 16): string => {
  // eslint-disable-next-line prefer-template, prettier/prettier, quotes
  return (1 / base) * px + 'rem'
}

export const fontSizes = {
  '1xs': remCalc(10), // caption
  xs: remCalc(12), // body-xs
  sm: remCalc(14), // body-sm
  md: remCalc(16), // body, h6
  lg: remCalc(18), // body-lg
  xl: remCalc(20), // h5
  '1xl': remCalc(24), // body-xl, h4 (mobile)
  '2xl': remCalc(28), // h4
  '3xl': remCalc(40), // h3
  '4xl': remCalc(50), // h2
  '5xl': remCalc(56), // h1, h1-condensed
  '6xl': remCalc(80), // hero (tablet)
  '7xl': remCalc(92), // hero, hero-condensed
  '8xl': remCalc(100), // hero, hero-condensed
}
