export default function poll(
  fn: () => boolean,
  interval: number,
  timeout: number
) {
  return new Promise((resolve, reject) => {
    const intervalHandle = setInterval(() => {
      if (fn()) {
        clearInterval(intervalHandle)
        resolve()
      }
    }, interval)
    setTimeout(() => {
      clearInterval(intervalHandle)
      reject(new Error('polling timed out'))
    }, timeout)
  })
}
