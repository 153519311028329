import { useState } from 'react'

import useCart from '@fe/providers/cart'
import { trackEvent } from '@fe/services/analytics'
import {
  couponApplied,
  couponEntered,
  couponFailed,
  couponRemoved,
} from '@fe/services/rudderstack/events/coupons'

export const tryApplyDiscount = async (
  token: string,
  discountCode: string,
  setIsTryingDiscount,
  updateCart,
  cartId
) => {
  trackEvent('track.discount.check', { code: discountCode })
  couponEntered(discountCode, cartId)

  try {
    setIsTryingDiscount(true)

    // !TODO: this object is actually the whole cart, so all track.discount.success events are sending `undefined` for amount and discount type
    const discount = await updateCart(token, {
      discount_codes: [
        {
          code: discountCode,
        },
      ],
    })
    setIsTryingDiscount(false)
    if (discount) {
      couponApplied(discount.discountCodes, cartId)
      trackEvent('track.discount.success', {
        code: discountCode,
        amount: discount.amount,
        type: discount.discountType,
      })
    }
  } catch (err) {
    trackEvent('track.discount.failed', { code: discountCode })
    couponFailed(discountCode, cartId)
    setIsTryingDiscount(false)
    throw err
  }
}

export const tryRemoveDiscount = async (
  token: string,
  setIsTryingDiscount,
  updateCart,
  cartId
) => {
  try {
    setIsTryingDiscount(true)

    const cart = await updateCart(token, {
      discount_codes: [
        {
          code: '',
        },
      ],
    })
    setIsTryingDiscount(false)
    if (cart) {
      couponRemoved(cart.discountCodes, cartId)
      trackEvent('track.discount.success', {
        code: '',
        amount: null,
        type: null,
      })
    }
  } catch (err) {
    trackEvent('track.discount.failed', { code: '' })
    couponFailed('', cartId)
    setIsTryingDiscount(false)
    throw err
  }
}

export default function useTryApplyDiscount() {
  const [isTryingDiscount, setIsTryingDiscount] = useState(false)
  const { updateCart } = useCart()

  return {
    isTryingDiscount,
    tryApplyDiscount: async (
      token: string,
      discountCode: string,
      cartId: number
    ) => {
      await tryApplyDiscount(
        token,
        discountCode,
        setIsTryingDiscount,
        updateCart,
        cartId
      )
    },
    tryRemoveDiscount: async (token: string, cartId: number) => {
      await tryRemoveDiscount(token, setIsTryingDiscount, updateCart, cartId)
    },
  }
}
