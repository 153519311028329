import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import config from '@fe/config'

const ignoredKeywords = [
  'Window navigated away',
  'ReferenceError',
  'TypeError',
  'ResizeObserver loop limit exceeded',
  'Window closed',
  'ChunkLoadError',
  'klaviyo',
]

const sentryOptions: Sentry.BrowserOptions = {
  attachStacktrace: true,
  dsn: config.sentry.dsn,
  environment: config.env,
  ignoreErrors: ignoredKeywords,
  integrations: [new Integrations.Dedupe(), new Sentry.Replay()],
  maxBreadcrumbs: 50,
  release: config.release,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1,
  // whitelistUrls: [/https?:\/\/(secure|secure2).allplants\.com/]
}

export default function logger(err, ctx: any = undefined) {
  // When we're developing locally
  if (config.env !== 'production') {
    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      ...(sentryOptions.integrations as Array<any>),
      new Integrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ]
  }

  // Get the FS session url
  let fsSessionUrl = null
  if (typeof window !== 'undefined' && window.FS) {
    try {
      fsSessionUrl = window.FS.getCurrentSessionURL(true)
    } catch (error) {
      // Fail silently if the session URL doesn't exist
    }
  }

  Sentry.init(sentryOptions)

  if (Sentry) {
    try {
      Sentry.configureScope((scope) => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message])
        }

        if (fsSessionUrl) {
          scope.setExtra('fsSessionURL', fsSessionUrl)
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode)
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode)
          }

          if (typeof window !== 'undefined') {
            scope.setExtra('query', query)
            scope.setExtra('pathname', pathname)
          } else {
            scope.setExtra('url', req.url)
            scope.setExtra('method', req.method)
            scope.setExtra('headers', req.headers)
            scope.setExtra('params', req.params)
            scope.setExtra('query', req.query)
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) =>
              scope.setExtra(key, errorInfo[key])
            )
          }
        }
      })
      return Sentry.captureException(err)
    } catch (e) {
      // fail silently if something goes wrong with Sentry
    }
  }
  return null
}
