import * as e from '@fe/components/Elements'
import styled from '@fe/styles'

import * as c from './components'

export const Container = styled(e.Collapse.CollapseContent)``
export const Content = styled.div`
  margin: 0 auto;
  padding: ${(p) => p.theme.layout.padding};
  overflow-y: scroll;
`
export const List = styled.ul`
  margin: 0;
  padding-left: ${(p) => p.theme.layout.spacingHorizontal};
`
export const { ErrorText } = c
export const Item = (props) => <c.ErrorText as='li' {...props} />
