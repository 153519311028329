export default function AllInline(props) {
  return (
    <svg fill='none' viewBox='0 0 96 96' {...props}>
      <g clipPath='url(#payment-request-inline_svg__clip2)'>
        <path
          d='M72.22 15.153c-2.579 3.04-6.704 5.438-10.828 5.095-.516-4.11 1.503-8.477 3.867-11.175C67.837 5.948 72.349 3.721 76 3.55c.43 4.282-1.246 8.478-3.781 11.603zm3.738 5.909c-5.973-.343-11.086 3.382-13.922 3.382-2.879 0-7.219-3.21-11.945-3.125-6.145.085-11.86 3.553-14.996 9.077-6.446 11.046-1.676 27.402 4.554 36.394 3.051 4.453 6.703 9.334 11.516 9.162 4.555-.17 6.36-2.954 11.86-2.954 5.542 0 7.132 2.954 11.945 2.869 4.984-.086 8.12-4.453 11.171-8.906 3.481-5.052 4.899-9.976 4.985-10.233-.086-.086-9.625-3.725-9.711-14.686-.086-9.163 7.52-13.53 7.863-13.787-4.297-6.337-11-7.022-13.32-7.193zm34.504-12.417v66.75h10.398v-22.82h14.395c13.148 0 22.387-8.992 22.387-22.008 0-13.016-9.067-21.922-22.043-21.922h-25.137zm10.398 8.735h11.989c9.023 0 14.179 4.795 14.179 13.23s-5.156 13.273-14.222 13.273H120.86V17.38zm55.774 58.53c6.531 0 12.59-3.297 15.34-8.52h.214v8.006h9.625V42.17c0-9.633-7.734-15.842-19.636-15.842-11.043 0-19.207 6.294-19.508 14.943h9.367c.774-4.11 4.598-6.808 9.84-6.808 6.359 0 9.926 2.955 9.926 8.392v3.683l-12.977.77c-12.074.728-18.605 5.652-18.605 14.215 0 8.65 6.746 14.387 16.414 14.387zm2.793-7.921c-5.543 0-9.067-2.655-9.067-6.723 0-4.196 3.395-6.636 9.883-7.021l11.559-.728v3.767c0 6.252-5.328 10.705-12.375 10.705zm35.234 25.56c10.141 0 14.91-3.853 19.078-15.541l18.262-51.037h-10.57l-12.246 39.433h-.215l-12.246-39.433h-10.872l17.618 48.596-.946 2.954c-1.589 5.01-4.168 6.937-8.765 6.937-.817 0-2.407-.086-3.051-.172v8.007c.602.171 3.18.257 3.953.257z'
          fill='#000'
        />
      </g>
      <defs>
        <clipPath id='payment-request-inline_svg__clip2'>
          <path d='M32 .55h220v90H32z' fill='#fff' />
        </clipPath>
      </defs>
    </svg>
  )
}
