import * as React from 'react'

import { connect, Field } from 'formik'
import styled from 'styled-components'

import { InputContainerCss, InputTextCss, IInputContainer } from './components'
import InputWrapper from './inputWrapper'

const TextareaContainer = styled.div<IInputContainer>`
  ${InputContainerCss}
  padding-top: 10px;
  height: auto;
`
const Textarea = styled.textarea<{
  isShowingLabel: boolean
}>`
  ${InputTextCss}
  resize: vertical;
  line-height: 26px;
  min-height: 64px;
  display: block;
`

const TextareaCharactersLength = styled.div`
  position: relative;
  font-size: 16px;
  color: ${(p) => p.theme.color.input.placeholder};
`

class TextareaComponent extends InputWrapper<{
  maxLength?: number
}> {
  public renderInput = () => {
    const { disabled, maxLength, ...rest } = this.props

    return (
      <>
        <TextareaContainer
          disabled={disabled}
          isDirty={this.isDirty}
          isFocused={this.isFocused}
          isValid={this.isValid}
          name={this.name}
        >
          <Field name={this.name}>
            {({ field }) => (
              <Textarea
                disabled={disabled}
                id={this.name}
                isShowingLabel={!this.isEmpty}
                isValid={this.isValid}
                {...rest}
                {...field}
              />
            )}
          </Field>
        </TextareaContainer>
        {maxLength && (
          <TextareaCharactersLength>
            {this.value ? this.value.length : 0}/{maxLength} characters
          </TextareaCharactersLength>
        )}
      </>
    )
  }
}

export default connect(TextareaComponent)
