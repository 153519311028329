import { Blank } from '@fe/components/Elements/button'
import styled, { css } from '@fe/styles'

// eslint-disable-next-line import/prefer-default-export
export const Dropdown = {
  variant: {
    primary: css`
      background-color: white;
    `,
    secondary: css`
      background-color: ${(p) => p.theme.color.yellow};
    `,
  },
  Container: styled.ul`
    width: 200px;
    padding: 0;
    margin: 0;
    list-style: none;
  `,
  Item: styled(Blank)<{
    variant?: 'primary' | 'secondary'
  }>`
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    width: 100%;
    ${(p) => p.theme.layout.paddingResizeCss}
    margin: ${(p) => p.theme.layout.spacingVertical} 0;
    ${(p) => Dropdown.variant[p.variant || 'primary']}
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  `,
  Break: styled.hr``,
}
