import styled, { css } from '@fe/styles'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: ${(p) => p.theme.layout.spacingVertical};
`
export const TBody = styled.tbody``
export const Row = styled.tr<{
  isTotal?: boolean
}>`
  font-weight: bold;
  border: ${(p) => p.theme.other.borderLight};
  ${(p) =>
    p.isTotal &&
    css`
      font-size: 20px;
    `}
`
export const Cell = styled.td`
  padding: 8px 12px;
`
export const CellLabel = styled(Cell)``
export const CellValue = styled(Cell)`
  text-align: right;
`
