import * as React from 'react'

import * as Sentry from '@sentry/browser'
import urlBuilder from 'build-url'
import Head from 'next/head'
import shortid from 'shortid'

import config from '@fe/config'

export default class MentionMeReferred extends React.Component<{
  className?: string
  situation: string
}> {
  private _id: number = shortid()

  private clearFiredTags = () => {
    // Always clear the link/checkout situation before loading. This will ensure the
    // Mention Me tag will be shown on refresh or page change.

    if (
      typeof window !== 'undefined' &&
      window.MentionMeFiredTags !== undefined
    ) {
      const { situation } = this.props
      const implementation = 'link'
      if (window.MentionMeFiredTags[implementation + situation]) {
        delete window.MentionMeFiredTags[implementation + situation]
      }
    }
  }

  public componentDidCatch = (e) => {
    Sentry.captureException(e)
  }

  public UNSAFE_componentWillMount = () => {
    this.clearFiredTags()
  }

  public render = () => {
    const { situation, ...rest } = this.props
    if (typeof window === 'undefined') return null
    this.clearFiredTags()
    return (
      <>
        {/* <!-- Mention Me script --> */}
        <Head>
          <script
            async
            src={urlBuilder(config.services.mentionMe.domain, {
              path: `/api/v2/refereefind/${config.services.mentionMe.partnerCode}`,
              queryParams: {
                situation,
                locale: 'en_GB',
                implementation: 'link',
                // eslint-disable-next-line no-underscore-dangle
                a: this._id,
              } as any,
            })}
            type='text/javascript'
          />
        </Head>
        {/* <!-- End Mention Me script --> */}
        {/* <!-- Begin Mention Me referee placeholder div --> */}
        <div id='mmWrapper' {...rest} />
        {/* <!-- End Mention Me referee placeholder div --> */}
      </>
    )
  }

  // private allowScriptRerun = () =>
}
