/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'

import { connect, Field, FieldProps } from 'formik'
import ReactAutosuggest, {
  AutosuggestProps as ReactAutosuggestProps,
} from 'react-autosuggest'

import { trackSelect } from '@fe/services/analytics'
import styled from '@fe/styles'

import {
  InputContainerCss,
  InputTextCss,
  InputButton,
  IInputContainer,
} from './components'
import InputWrapper from './inputWrapper'

const Styling = styled.div<
  {
    isShowingLabel: boolean
  } & IInputContainer
>`
  width: 100%;
  position: relative;

  .react-autosuggest__container {
    position: relative;
    ${InputContainerCss}
  }

  .react-autosuggest__input {
    ${InputTextCss}
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    width: calc(100% + 2px);
    position: relative;
    left: -1px;
    min-height: 60px;
    border: 1px solid ${(p) => p.theme.color.lightGrey};
    border-top: 0;
    background-color: #fff;
    font-size: 16px;
    z-index: ${(p) => p.theme.depth.foreground};
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    cursor: pointer;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestion {
    padding: 6px 10px;
    margin: 6px 0;
    color: ${(p) => p.theme.color.input.default};
    transition: ${(p) => p.theme.transition.default};
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${(p) => p.theme.color.input.optionHover};
    transition: ${(p) => p.theme.transition.quick};
  }
`
const Suggestion = styled.div`
  cursor: pointer;
`

type AutosuggestProps<T> = ReactAutosuggestProps<any, any> & {
  allowCustomValue?: boolean
  defaultErrorMessage?: string
  getSuggestionLabel?: (suggestion: T) => string | JSX.Element
  getSuggestionLabelText?: (suggestion: T) => string
  getValueFromSelected?: (suggestion: T, value: string) => string
  isManualTrigger?: boolean
  manualTriggerText?: string
  value: T
  validate?: (value?: string) => string | undefined
}

class AutosuggestInput<T> extends InputWrapper<
  AutosuggestProps<T>,
  {
    lastInputValue: string
    selectedInputValue: string
    selectedSuggestion: T | null
    valueSelected: boolean
  }
> {
  public static defaultProps = {
    ...InputWrapper.defaultProps,
    highlightFirstSuggestion: true,
    defaultErrorMessage: 'please enter a valid value',
    getSuggestionValue: (suggestion) => suggestion.value,
    getSuggestionLabelText: (suggestion) => suggestion.label,
    getSuggestionLabel: (suggestion) => suggestion.label,
    allowCustomValue: false,
    getValueFromSelected: (suggestion) => suggestion,
    isManualTrigger: false,
    manualTriggerText: 'search',
    validate: () => undefined,
  }

  private inputRef: HTMLInputElement

  constructor(props: any) {
    super(props)
    const value = props.formik.values[props.name] || ''
    this.state = {
      ...this.state,
      valueSelected: !!value,
      lastInputValue: value,
      selectedInputValue: value,
      selectedSuggestion: null,
    }
  }

  private onSuggestionSelected: ReactAutosuggest.OnSuggestionSelected<T> = (
    event,
    d
  ) => {
    const value = this.props.getSuggestionValue(d.suggestion)

    this.setState({
      valueSelected: true,
      selectedInputValue: value,
      selectedSuggestion: d.suggestion,
    })
    this.props.formik.setFieldValue(this.name, value)
    this.props.onSuggestionSelected!(event, d)
    void trackSelect('typeahead', this.name, d.suggestion)
  }

  public onChange = (
    _event: React.FormEvent<any>,
    params: ReactAutosuggest.ChangeEvent
  ): void => {
    if (params.method !== 'click')
      this.props.formik.setFieldValue(this.name, params.newValue)
  }

  public renderSuggestion = (suggestion: any): JSX.Element => (
    <Suggestion>{this.props.getSuggestionLabel!(suggestion)}</Suggestion>
  )

  public renderInput = () => {
    const {
      placeholder,
      onSuggestionsFetchRequested,
      onSuggestionSelected,
      allowCustomValue,
      disabled,
      renderSuggestion,
      manualTriggerText,
      isManualTrigger,
      validate,
      inputProps,

      ...rest
    } = this.props

    return (
      <Styling
        disabled={!!disabled}
        isDirty={this.isDirty}
        isFocused={this.isFocused}
        isShowingLabel={!this.isEmpty}
        isValid={this.isValid}
        name={this.name}
      >
        <Field name={this.name} validate={validate}>
          {(props: FieldProps) => (
            <>
              <ReactAutosuggest
                inputProps={{
                  ...inputProps,
                  placeholder,
                  ...props.field,
                  value: props.field.value || '',
                  // value: props.field.value || '',
                  // onChange: this.onChange,
                }}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsFetchRequested={(request) => {
                  if (!isManualTrigger && onSuggestionsFetchRequested)
                    onSuggestionsFetchRequested(request)
                }}
                renderInputComponent={({ ref, ...inputRest }: any) => (
                  <input
                    {...inputRest}
                    ref={(i) => {
                      ref(i)
                      this.inputRef = i!
                    }}
                    id={this.name}
                    name={this.name}
                  />
                )}
                renderSuggestion={renderSuggestion || this.renderSuggestion}
                {...rest}
              />
              {isManualTrigger && (
                <InputButton
                  className='find-postcode'
                  disabled={!this.value}
                  onClick={() => {
                    onSuggestionsFetchRequested!({
                      value: this.value,
                      reason: 'input-changed',
                    })
                    if (this.inputRef) this.inputRef.focus()
                  }}
                  type='button'
                  variant='secondary'
                >
                  {manualTriggerText!}
                </InputButton>
              )}
            </>
          )}
        </Field>
      </Styling>
    )
  }
}

export default connect(AutosuggestInput)
