export default function DeliveryVan(props) {
  return (
    <svg
      fill='none'
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        clipRule='evenodd'
        d='M5 8C4.44772 8 4 8.44772 4 9V22V29V33C4 33.5523 4.44771 34 5 34H8C8 31.2386 10.2386 29 13 29C15.7614 29 18 31.2386 18 34H30C30 31.2386 32.2386 29 35 29C37.7614 29 40 31.2386 40 34H43C43.5523 34 44 33.5523 44 33V23C44 22.4477 43.5523 22 43 22H39.0611L36.7863 16.798C36.3086 15.7058 35.2297 15 34.0376 15H32V9C32 8.44772 31.5523 8 31 8H5ZM35.0153 17.5896L37 22H32V17H34.1034C34.4969 17 34.8539 17.2308 35.0153 17.5896ZM19.7819 23.4897C19.5754 23.6815 20.3431 24.1288 20.4789 24.2063C21.4048 24.736 22.4713 24.7169 23.4146 24.3378C23.4138 24.2625 23.4215 24.1864 23.4391 24.1116C23.4419 24.1001 23.7118 22.9591 23.8711 21.8209L24 20.9008C23.9055 20.9997 23.8037 21.0916 23.6909 21.1716C23.5158 21.2958 23.3125 21.3921 23.0983 21.396C23.0664 21.3964 23.0346 21.394 23.0033 21.3898L23.4815 17.975C23.9515 14.6184 21.8485 13 18.8021 13C16.1342 13 14.5723 14.0589 14.3707 15.4974C14.2448 16.3966 14.9229 16.956 15.819 16.956C15.9188 16.3185 16.7228 15.585 18.0292 15.8172C19.4605 16.0717 20.1846 16.6364 19.9942 17.995L19.9859 18.0549C19.3734 17.7353 18.6307 17.4955 17.7545 17.4955C15.1063 17.4955 13.3148 18.9142 13.0349 20.9121C12.7663 22.8301 14.0503 24.6083 16.7583 24.6083C18.0523 24.6083 19.0272 24.1889 19.7819 23.4897ZM16.2717 20.8322C16.3613 20.1929 17.0231 19.7333 17.8392 19.7333C18.6756 19.7333 19.1851 19.9332 19.6864 20.1929C19.4645 21.3517 18.527 21.9312 17.5314 21.9312C16.6751 21.9312 16.1794 21.4916 16.2717 20.8322ZM17 34C17 36.2091 15.2091 38 13 38C10.7909 38 9 36.2091 9 34C9 31.7909 10.7909 30 13 30C15.2091 30 17 31.7909 17 34ZM15 34C15 35.1046 14.1046 36 13 36C11.8954 36 11 35.1046 11 34C11 32.8954 11.8954 32 13 32C14.1046 32 15 32.8954 15 34ZM35 38C37.2091 38 39 36.2091 39 34C39 31.7909 37.2091 30 35 30C32.7909 30 31 31.7909 31 34C31 36.2091 32.7909 38 35 38ZM35 36C36.1046 36 37 35.1046 37 34C37 32.8954 36.1046 32 35 32C33.8954 32 33 32.8954 33 34C33 35.1046 33.8954 36 35 36Z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}
