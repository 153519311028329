import { fontSizes } from './font-sizes'
import { fontWeights } from './font-weights'
import { fonts } from './fonts'
import { lineHeights } from './line-heights'

const { base, none } = lineHeights
const { ultra } = fontWeights

// eslint-disable-next-line import/prefer-default-export
export const textStyles = {
  h2: {
    fontSize: fontSizes['3xl'],
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes['5xl'],
    },
    lineHeight: none,
    fontWeight: ultra,
    fontFamily: fonts.heading,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },

  h3: {
    fontSize: fontSizes['2xl'],
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes['3xl'],
    },
    fontWeight: ultra,
    lineHeight: none,
    fontFamily: fonts.heading,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },

  h4: {
    fontSize: fontSizes['1xl'],
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes['2xl'],
    },
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    textTransform: 'uppercase',
  },

  h5: {
    fontSize: fontSizes.lg,
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes.xl,
    },
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    textTransform: 'uppercase',
  },

  h6: {
    fontSize: fontSizes.md,
    fontWeight: ultra,
    fontFamily: fonts.heading,
    lineHeight: base,
    letterSpacing: '.5px', // move to theme
    textTransform: 'uppercase',
  },

  'body-xl': {
    fontSize: fontSizes.md,
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes['1xl'],
    },

    lineHeight: base, // 34px
  },

  'body-lg': {
    fontSize: fontSizes.md,
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes.lg,
    },
  },

  /**
   * when using font weight 400 increase the line height to 24px (large)
   */
  body: {
    fontSize: fontSizes.sm,
    '@media screen and (min-width: 40em)': {
      fontSize: fontSizes.md,
    },

    lineHeight: base,
  },

  'body-sm': {
    fontSize: fontSizes.sm,

    lineHeight: base,
  },

  'body-xs': {
    fontSize: fontSizes.xs,

    lineHeight: base,
  },

  caption: {
    fontSize: fontSizes['1xs'],

    lineHeight: base,
  },
}
