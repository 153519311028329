/* eslint-disable max-classes-per-file */
import * as React from 'react'

import { Field, FieldProps } from 'formik'
import styled, { css } from 'styled-components'

import { trackSelect } from '@fe/services/analytics'

const RadioButtonContainer = styled.div<{
  addVerticalPadding: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
  font-family: ${(p) => p.theme.font.allplants};
  padding: ${(p) => p.addVerticalPadding && '10px 0'};
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.1;
      pointer-events: none;
    `}
`

const RadioButtonLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  width: 100%;
`

const RadioButton = styled.span<{
  checked: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.color.lightGrey};
  :after {
    content: "";
    position: absolute;
    display: ${(p) => {
      if (p.checked) {
        return 'block;'
      }
      return 'none;'
    }}
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #000;
  }
`

const LabelContainer = styled.div<{
  checked: boolean
  fadeWhenUnchecked?: boolean
  isBold?: boolean
}>`
  color: ${(p) =>
    !p.checked && p.fadeWhenUnchecked ? p.theme.color.lightGrey : '#000'};
  font-size: 18px;
  ${(p) =>
    p.isBold &&
    css`
      font-weight: 500;
    `}
`
const Input = styled.input<{
  isValid: boolean
}>`
  position: absolute;
  opacity: 0;
`
const ErrorMessage = styled.div``

interface IRadioGroup {
  name: string
  getValue: () => string
  setValue: (value) => void
  isValid: () => boolean
  getErrorMessage: () => string | undefined
  isPristine: () => boolean
}
export const RadioContext = React.createContext<IRadioGroup>(undefined as any)

export function RadioGroup<TValue = string, TForm = Record<string, unknown>>({
  name,
  children,
  onSelected = () => undefined,
}: {
  children: JSX.Element | ((value: IRadioGroup) => JSX.Element)
  name: string
  onSelected?: (value: TValue, form: TForm) => void
}) {
  return (
    <Field name={name}>
      {(props: FieldProps) => {
        const { form, field } = props
        return (
          <RadioContext.Provider
            value={{
              name,
              getValue: () => field.value,
              setValue: (value) => {
                form.setFieldValue(name, value)
                onSelected(value, {
                  ...form.values,
                  [name]: value,
                })
              },
              isValid: () => !!form.errors[name],
              getErrorMessage: () => form.errors[name] as string,
              isPristine: () => form.touched[name] as boolean,
            }}
          >
            {children}
          </RadioContext.Provider>
        )
      }}
    </Field>
  )
}
// eslint-disable-next-line max-classes-per-file
export default function Radio({
  children,
  value,
  addVerticalPadding,
  fadeUncheckedLabel,
  disabled,
  isBold,
  ...rest
}: {
  children: string | JSX.Element
  value: string
  addVerticalPadding?: boolean
  fadeUncheckedLabel?: boolean
  disabled?: boolean
  isBold?: boolean
}) {
  const group = React.useContext(RadioContext)
  if (!group) throw new Error('need to be part the child of a RadioGroup')
  const isDirty = !group.isPristine()

  const isValidValue = group.isValid()
  const checked = value === group.getValue()

  return (
    <RadioButtonContainer
      addVerticalPadding={addVerticalPadding || false}
      disabled={disabled}
    >
      <RadioButtonLabel>
        <Input
          checked={checked}
          disabled={disabled}
          isValid={isValidValue}
          name={group.name}
          onChange={() => {
            group.setValue(value)
            void trackSelect('radio', group.name, {
              value,
            })
          }}
          type='radio'
          {...rest}
        />
        <RadioButton checked={checked} />
        <LabelContainer
          checked={checked}
          fadeWhenUnchecked={fadeUncheckedLabel}
          isBold={isBold}
        >
          {children}
        </LabelContainer>
      </RadioButtonLabel>
      {!isValidValue && isDirty && (
        <ErrorMessage>{group.getErrorMessage()}</ErrorMessage>
      )}
    </RadioButtonContainer>
  )
}
