import Cookies from 'js-cookie'
import merge from 'lodash/merge'

export default function getCookieManager() {
  return {
    ...Cookies,
    merge: (key, data) => {
      const dataTextWas = Cookies.get(key)
      let newData = data
      if (dataTextWas) {
        const dataWas = JSON.parse(dataTextWas)
        newData = merge(dataWas, data)
      }
      Cookies.set(key, JSON.stringify(newData))
      return newData
    },
  }
}
