import styled from 'styled-components'
import { textStyle, space, color, typography } from 'styled-system'

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.div`
  ${space}
  ${color}
  ${typography}
  ${textStyle}
`
