import styled from 'styled-components'
import { space, color, typography, layout, flexbox } from 'styled-system'

// eslint-disable-next-line import/prefer-default-export
export const Box = styled.div`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
`
