import * as e from '@fe/components/Elements'
import MentionMeReferred from '@fe/components/common/MentionMe/referred'
import styled from '@fe/styles'

export const MentionMe = styled(MentionMeReferred)`
  line-height: 20px;
  color: ${(p) => p.theme.color.link.default};
  text-align: right;
  a {
    font-size: 16px;
    color: inherit;
    text-transform: lowercase !important;
    text-decoration-color: inherit;
  }
`

export const OrderDiscount = styled(e.Container.ColumnContainer)`
  display: flex;
`

const HeaderText = styled.h3`
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0;
`

export const Price = styled(HeaderText)`
  flex-direction: column;
  font-size: 16px;
  ${(p) => p.theme.media.phablet`
    font-size: 18px;
  `}
`

export const Header = styled(HeaderText)`
  width: 100%;
  max-width: 320px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin-bottom: 12px;
  text-align: left;
  ${(p) => p.theme.media.phablet`
    font-size: 16px;
    margin-bottom: 6px;
  `}
`

export const DiscountText = styled.span`
  text-decoration: line-through;
`

export const DiscountInputContainer = styled.div`
  max-width: 100%;
  ${(p) => p.theme.media.desktop`
    max-width: 300px;
  `}
`

export const DiscountOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${(p) => p.theme.media.tablet`
    flex-direction: row;
  `}
`

export const DiscountLink = styled.button`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
  background: none;
  padding: 0;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  color: ${(p) => p.theme.color.black};
  ${(p) => p.theme.media.phablet`

    line-height: 20px;
  `}
`

export const RegularText = styled(e.Text.Regular)`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
  ${(p) => p.theme.media.phablet`
    font-size: 16px;
    line-height: 20px;
  `}
`

export const SuccessMessage = styled.p`
  color: ${(p) => p.theme.color.lightGreen};
  margin: 4px 0 0;
`
