export function penceToPounds(pennies: number): number {
  return pennies / 100
}

export function currency(pennies: number, currencySymbol = '£'): string {
  const isNegative = pennies < 0
  const pounds = penceToPounds(pennies)
  return `${isNegative ? '-' : ''}${currencySymbol}${Math.abs(pounds).toFixed(
    2
  )}`
}
