import { DefaultUniversalButton } from '@fe/components/UniversalButton'
import styled, { css } from '@fe/styles'

import * as ButtonComponents from './button'

export const Basic = styled(DefaultUniversalButton)<{
  color?: string
  isNotDecorated?: boolean
}>`
  cursor: pointer;
  text-decoration: underline;
  color: ${(p) => p.color || p.theme.color.link.default};
  ${(p) =>
    p.isNotDecorated &&
    css`
      text-decoration: none;
    `};
`
export const Button = styled((props) => (
  <ButtonComponents.Basic as={DefaultUniversalButton} {...props} />
))`
  text-decoration: none;
`

export const Blank = styled(DefaultUniversalButton)`
  cursor: pointer;
  outline: none !important;
  border: none;
  background: transparent;
  text-decoration: none;
`

export default Basic
