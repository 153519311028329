import { css, InterpolationValue } from 'styled-components'

type MediaQuery = (...args: any[]) => InterpolationValue[]
export interface IMedia {
  custom: (size: string) => MediaQuery
  desktop: MediaQuery
  largeDesktop: MediaQuery
  phablet: MediaQuery
  phone: MediaQuery
  sizes: {
    desktop: number
    largeDesktop: number
    phablet: number
    phone: number
    tablet: number
  }
  tablet: MediaQuery
}

export const sizes = {
  largeDesktop: 1200,
  desktop: 992,
  tablet: 770,
  phablet: 480,
  phone: 320,
}

const mediaTags = Object.keys(sizes).reduce((acc: any, label) => {
  acc[label] = (firstArg, ...args: any[]) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(firstArg, ...args)}
    }
  `
  return acc
}, {})

const media: IMedia = {
  ...mediaTags,
  sizes,
  custom:
    (size) =>
    (firstArg, ...args: any[]) =>
      css`
        @media (min-width: ${size}) {
          ${css(firstArg, ...args)}
        }
      `,
}

export default media
