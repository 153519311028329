const defaultErrorMessage = 'This is not a valid discount code'

const errorMessagesByErrorCode = new Map([
  [
    'discount_minimum_spend',
    'You have not reached the minimum amount required to use this discount code.',
  ],
  [
    'discount_new_customers_only',
    'Sorry, the code won’t work with this order.',
  ],
  [
    'discount_valid_on_one_off_only',
    'Sorry, this discount code is only valid for one-off orders.',
  ],
  [
    'discount_valid_on_subscription_only',
    'Sorry, this discount code is only valid for subscription orders.',
  ],
])

export { defaultErrorMessage, errorMessagesByErrorCode }
