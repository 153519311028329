import styled, { css } from '@fe/styles/styledComponents'

const ParagraphSize = {
  large: css`
    font-size: 24px;
    line-height: 27px;
  `,
  medium: css`
    font-size: 20px;
    line-height: 24px;
  `,
  small: css`
    font-size: 18px;
    line-height: 21px;
  `,
}

export interface IBaseText {
  isCentered?: boolean
  variant?: 'primary' | 'secondary'
  color?: string
}
const variantStyle = {
  primary: css`
    color: black;
  `,
  secondary: css`
    color: white;
  `,
}
export const ResetCss = css`
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.2em;
`

const baseText = css<IBaseText>`
  ${ResetCss}
  font-family: ${(p) => p.theme.font.allplants};
  font-weight: 400;
  ${(p) =>
    p.isCentered &&
    css`
      width: 100%;
      text-align: center;
    `}
  color: ${(p) => p.color && p.color};
  ${(p) => p.variant && variantStyle[p.variant || 'primary']}
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseHeader extends IBaseText {}
const baseHeader = css<IBaseHeader>`
  ${baseText}
  font-family: ${(p) => p.theme.font.header};
  margin-top: 0.1em;
  margin-bottom: 0.3em;
`

const isBold = css<{
  // eslint-disable-next-line no-use-before-define
  isBold: boolean
}>`
  ${(p) =>
    p.isBold &&
    css`
      font-weight: bold;
    `};
`
const noSpacing = css<{
  // eslint-disable-next-line no-use-before-define
  noSpacing: boolean
}>`
  ${(p) =>
    p.noSpacing &&
    css`
      margin-bottom: 0 !important;
    `};
`

export const Regular = styled.div<{
  isBold?: boolean
  noSpacing?: boolean
}>`
  ${baseText}
  font-size: 16px;
  ${isBold};
  ${noSpacing};
`
export const Paragraph = styled.p<{
  isBold?: boolean
  noSpacing?: boolean
  size?: 'large' | 'small'
}>`
  ${(p) => ParagraphSize[p.size || 'medium']};
  ${(p) =>
    p.noSpacing
      ? css`
          margin: 0;
        `
      : css`
          margin: 0 0 8px;
        `}
  ${isBold}
`

export const Title = styled.div`
  ${baseText}
  font-size: 16px;
`

export const Header = styled.div`
  ${baseText}
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;

  ${(p) => p.theme.media.desktop`
    font-size: 34px;
  `};
`

export const FormTitleText = styled.h3`
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  margin: 0;
`

export const FormHelpText = styled(Title)`
  color: ${(p) => p.theme.color.darkGrey};
  margin-top: 10px;
`

export const h1 = styled.h1<IBaseHeader>`
  ${baseHeader}
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 0;
  text-transform: lowercase;
  ${(p) => p.theme.media.phablet`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 50px;
  `}
`
export const h2 = styled.h2<IBaseHeader>`
  ${baseHeader}
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
  text-transform: lowercase;
  ${(p) => p.theme.media.phablet`
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 30px;
  `}
`
export const h3 = styled.h3<IBaseHeader>`
  ${baseHeader}
  font-weight: 600;
  font-size: 18px;
  line-height: 20.8px;
  letter-spacing: 0.5px;
  text-transform: lowercase;
  ${(p) => p.theme.media.phablet`
    font-size: 24px;
  `}
`
export const h4 = styled.h4<IBaseHeader>`
  ${baseHeader}
  font-size: 16px;
  line-height: 19px;
  ${(p) => p.theme.media.phablet`
    font-size: 18px;
    line-height: 23px;
  `}
`
const pVariant = {
  primary: css`
    color: #111111;
  `,
  secondary: css`
    color: white;
  `,
}
export const P = styled.p<IBaseHeader>`
  ${baseText}
  ${(p) => pVariant[p.variant || 'primary']}
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  ${(p) => p.theme.media.phablet`
    font-size: 18px;
    line-height: 23px;
  `}
`
export const h5 = styled(P)`
  font-weight: bold;
`

export const PSmall = styled.p<IBaseHeader>`
  ${baseText}
  ${(p) => pVariant[p.variant || 'primary']}
  font-size: 16px;
  line-height: 24px;
  ${(p) => p.theme.media.tablet`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const ItemDescription = styled.p`
  display: block;
  font-family: ${(p) => p.theme.font.allplants};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #111111;
  margin: ${(p) => p.theme.layout.spacingVertical} 0 0;
  text-transform: lowercase;
  text-align: center;
`
