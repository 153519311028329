import * as React from 'react'

import { Collapse } from 'react-collapse'

import styled from '@fe/styles/styledComponents'

export interface ICollapseProps {
  children: JSX.Element | Array<JSX.Element>
  isOpened: boolean
  onRest?: () => void
  removeWhenClosed?: boolean
  style?: any
}

const StyledCollapseContent = styled(Collapse as any)`
  width: 100%;
  /* Disable margin collapsing */
  > .ReactCollapse--content {
    display: inline-block;
    width: 100%;
  }
`
export class CollapseContent extends React.Component<
  ICollapseProps,
  {
    isAnimating: boolean
  }
> {
  public state = {
    isAnimating: false,
  }

  public render = () => {
    const { isAnimating } = this.state
    const { isOpened, removeWhenClosed } = this.props
    const { onRest, ...collapseProps } = this.props
    if (!isAnimating && !isOpened && removeWhenClosed) {
      return null
    }
    return (
      <StyledCollapseContent
        onRest={() => {
          const { isAnimating: isCurrentlyAnimating } = this.state
          if (isCurrentlyAnimating) {
            this.setState({ isAnimating: false })
          }
          if (onRest) onRest()
        }}
        {...collapseProps}
      />
    )
  }
}
