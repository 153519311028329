import * as React from 'react'

import { Field, ErrorMessage as FormikErrorMessage } from 'formik'

import * as e from '@fe/components/Elements'
import styled, { css } from '@fe/styles'

export interface IInputContainer {
  disabled?: boolean
  isValid?: boolean
  isFocused?: boolean
  isDirty?: boolean
}
export const InputContainerCss = css<IInputContainer>`
  padding: 1px;
  width: 100%;
  height: 56px;
  border: 2px solid ${(p) => p.theme.color.lightGrey};
  background: white;
  transition: ${(p) => p.theme.transition.default};
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      background: rgb(240, 240, 240);
      opacity: 0.6;
    `}
  ${(p) =>
    p.isDirty &&
    !p.isValid &&
    css`
      padding: 0;
      border-width: 2px;
      border-color: ${p.theme.color.red};
    `}
  ${(p) =>
    p.isFocused &&
    css`
      padding: 0;
      border-width: 2px;
      border-color: ${p.theme.color.yellow};
    `}
`
export const InputTextCss = css<{
  isShowingLabel: boolean
}>`
  outline: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  font-size: 17px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  line-height: 56px;
  padding-bottom: 0;
  transition: ${(p) => p.theme.transition.default};
  padding-top: ${(p) => (p.isShowingLabel ? '12px' : '0px')};
  font-weight: normal;
  color: ${(p) => p.theme.color.input.default};
  background: transparent;
  ::placeholder {
    color: ${(p) => p.theme.color.input.placeholder};
  }
`
export const InputContainer = styled.div`
  ${InputContainerCss}
`

export const SuffixIcon = styled.div`
  position: absolute;
  z-index: ${(p) => p.theme.depth.infront + 1};
  top: 18px;
  right: 12px;
  color: ${(p) => p.theme.color.lightGrey};
`

export const Icon: React.SFC<{
  icon: string
}> = ({ icon }) => {
  // eslint-disable-next-line import/namespace
  const SelectedIcon = e.Icon[icon] || null
  return (
    <SuffixIcon>
      <SelectedIcon />
    </SuffixIcon>
  )
}

export const LabelText = styled.label<{
  isOpaque?: boolean
  isShowing: boolean
}>`
  position: absolute;
  top: -6px;
  opacity: 0;
  left: 1px;
  right: 1px;
  padding-top: 5px;
  padding-left: 12px;
  font-size: 10px;
  font-weight: bold;
  font-family: inherit;
  text-transform: uppercase;
  text-align: left;
  z-index: ${(p) => p.theme.depth.infront - 1};
  pointer-events: none;
  text-align: left;
  transition: ${(p) => p.theme.transition.default};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(p) =>
    p.isShowing &&
    css`
      opacity: 1;
      top: 1px;
    `}
  ${(p) =>
    p.isOpaque &&
    css`
      background: white;
    `}
`
export const InputComponent = styled.input`
  ${InputTextCss}
`
export const Input = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field }) => <InputComponent {...field} {...rest} />}
  </Field>
)

export const ErrorText = styled.div<{
  isCentered?: boolean
}>`
  ${(p) =>
    p.isCentered
      ? css`
          margin: 0 auto;
        `
      : css`
          text-align: left;
        `}
  font-size: 16px;
  padding-top: 4px;
  color: ${(p) => p.theme.color.red};
`
export const ErrorMessage: React.SFC<{
  name: string
}> = (props) => (
  <FormikErrorMessage {...props}>
    {(message) => <ErrorText>{message}</ErrorText>}
  </FormikErrorMessage>
)

export const InputButton = styled(e.Button.Input)`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 40px;
  margin-bottom: 0;
  z-index: 10;
`
