export default function Search(props) {
  return (
    <svg
      id='Layer_1'
      style={{ enableBackground: 'new 0 0 38 39' }}
      version='1.1'
      viewBox='0 0 38 39'
      x='0px'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlSpace='preserve'
      y='0px'
      {...props}
    >
      <g id='Page-1'>
        <g
          id='Shop-Window_x2F_Our-Story_x2F_1200'
          transform='translate(-712.000000, -53.000000)'
        >
          <path
            d='M712.66,91.33l-0.08-0.08c-0.78-0.78-0.78-2.04,0-2.82l10.3-10.3c-5.4-6.1-5.25-15.26,0.33-20.84
			c5.85-5.85,15.52-5.69,21.57,0.35c2.92,2.92,4.57,6.77,4.64,10.83c0.07,4.08-1.45,7.9-4.29,10.74
			c-5.02,5.02-13.07,5.71-19.15,1.64l-10.49,10.49C714.71,92.12,713.44,92.12,712.66,91.33z M726.92,76.66l1.29,0.87
			c4.49,3.01,10.42,2.53,14.09-1.14c2.07-2.07,3.17-4.85,3.12-7.84c-0.05-3.02-1.29-5.89-3.47-8.07
			c-4.49-4.48-11.62-4.64-15.91-0.35c-4.09,4.09-4.16,10.84-0.16,15.36L726.92,76.66z'
          />
        </g>
      </g>
    </svg>
  )
}
