/* eslint-disable no-unused-expressions */

// Global styles

/* NPM */
import * as pose from './pose'
import styledComponents, {
  css,
  keyframes,
  ThemeProvider,
  createGlobalStyle,
  defaults,
} from './styledComponents'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${defaults.font.allplants};
  }
  html {
    padding: 0;
    border: 0;
    font-size: 16px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    padding: 0;
    border: 0;
    margin: 0;
    background-color: #F3F3F2;
  }
  body.modal-open {
    overflow: hidden;
  }
  body.modal-open-fixed {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
  ::selection {
    background-color: #000;
    color: #FFF;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeIn;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInDown {
    animation-name: fadeInDown;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInUp {
    animation-name: fadeInUp;
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInRight {
    animation-name: fadeInRight;
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  html {
    min-height: 100%;
    min-height: 100vh;
    overflow: auto;
  }
  body {
    background-color: #F3F3F2;
  }

  #mmLink {
    font-size 14px;
  }
`
export {
  css,
  keyframes,
  GlobalStyles,
  createGlobalStyle,
  ThemeProvider,
  defaults,
  pose,
}
export default styledComponents
export * from './rhiz'
