import styled, { css } from '@fe/styles'

// eslint-disable-next-line import/prefer-default-export
export const CardContainer = styled.div<{
  centerContent?: boolean
  isClickable?: boolean
}>`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: 60px;
  min-width: 100px;
  width: 100%;

  ${(p) =>
    p.isClickable &&
    css`
      transition: ${p.theme.transition.default};
      :hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      }
    `}

  ${(p) =>
    p.centerContent &&
    css`
      text-align: center;
    `}
`
