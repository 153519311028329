export default function SiteDown(props) {
  return (
    <svg
      height='24px'
      version='1.1'
      viewBox='0 0 31 24'
      width='31px'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g
        fill='none'
        fillRule='evenodd'
        id='Shop-Window'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fill='#FFFFFF'
          id='Shop-Window/Site-Down/1200'
          transform='translate(-258.000000, -24.000000)'
        >
          <g id='Site-down-banner'>
            <g id='Group-5' transform='translate(258.000000, 17.000000)'>
              <g id='Group-4' transform='translate(0.000000, 7.000000)'>
                <path
                  d='M29.9677895,20.1783158 L18.3476842,20.1783158 C18.2847726,20.1783158 18.2218611,20.2412274 18.2218611,20.3041389 L18.2218611,20.7778232 C18.2218611,21.0615411 18.0010547,21.3144126 17.6852716,21.3144126 L12.6635874,21.3156474 C12.3798695,21.3156474 12.1269979,21.0627695 12.1269979,20.7790579 L12.1269979,20.3374579 C12.1269979,20.2745463 12.0640863,20.2116347 12.0011747,20.2116347 L0.347911895,20.2104 C0.252928737,20.2104 0.157945579,20.3053832 0.157945579,20.4003663 L0.157945579,22.3271874 C0.157945579,23.2424716 0.915335053,24.0011242 1.83188242,24.0011242 L28.4838821,24.0011242 C29.3991663,24.0011242 30.1578189,23.2437347 30.1578189,22.3271874 L30.1578189,20.4003663 C30.1578189,20.2424716 30.0628358,20.1783158 29.9678526,20.1783158 L29.9677895,20.1783158 Z'
                  id='Fill-1'
                />
                <path
                  d='M3.91515789,18.7572632 L26.3684211,18.7572632 C27.1899789,18.7572632 27.8215579,18.0936 27.8215579,17.3041263 L27.8215579,3.28370526 C27.8215579,1.51477895 26.3684211,0.0629684211 24.6008211,0.0629684211 L5.6844,0.0629684211 C3.91547368,0.0629684211 2.46366189,1.51610526 2.46366189,3.28370526 L2.46366189,17.3041263 C2.46366189,18.1256842 3.12609474,18.7572632 3.91556842,18.7572632 L3.91515789,18.7572632 Z M5.40030947,3.28357895 C5.40030947,3.12568421 5.52617053,2.99986105 5.68406526,2.99986105 L24.6314337,2.99986105 C24.7893284,2.99986105 24.9151516,3.12568421 24.9151516,3.28357895 L24.9151516,15.8523158 L5.40030947,15.8523158 L5.40030947,3.28357895 Z'
                  id='Fill-2'
                />
                <path
                  d='M15.1578947,5.14768421 C12.7894737,5.14768421 10.8947368,7.07450526 10.8947368,9.41084211 C10.8947368,11.7471789 12.7894737,13.7049474 15.1578947,13.7049474 C17.5263158,13.7049474 19.4210526,11.7781263 19.4210526,9.44178947 C19.4210526,7.10545263 17.5263158,5.14768421 15.1578947,5.14768421 Z M16.8626526,10.2312632 C17.1155305,10.4841411 17.1155305,10.8949263 16.8626526,11.1786316 C16.7368295,11.3056863 16.5789347,11.3685979 16.4210526,11.3685979 C16.2631705,11.3685979 16.0731789,11.3056863 15.9473684,11.1786316 L15.1578947,10.3891579 L14.3684211,11.1786316 C14.2425979,11.3056863 14.0847032,11.3685979 13.8947368,11.3685979 C13.7368421,11.3685979 13.5468632,11.3056863 13.4210526,11.1786316 C13.1681747,10.9257537 13.1681747,10.5149684 13.4210526,10.2312632 L14.2105263,9.44178947 L13.4210526,8.65231579 C13.1681747,8.39943789 13.1681747,7.98865263 13.4210526,7.73703158 C13.6739305,7.48415368 14.0847158,7.48415368 14.3363368,7.73703158 L15.1578947,8.52650526 L15.9473684,7.73703158 C16.2002463,7.48415368 16.6110316,7.48415368 16.8626526,7.73703158 C17.1155305,7.98990947 17.1155305,8.40069474 16.8626526,8.65231579 L16.0731789,9.44178947 L16.8626526,10.2312632 Z'
                  id='Fill-3'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
