import * as React from 'react'

import access from 'safe-access'

import * as e from '@fe/components/Elements'
import { trackEvent } from '@fe/services/analytics'
import styled, { css } from '@fe/styles'

import * as c from './components'
import { InputInternalProps } from './types'

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: ${(p) => p.theme.font.allplants};
`

const LoadingContainer = styled.div<{
  isLoading?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  text-align: right;
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: ${(p) => p.theme.transition.default};
  z-index: ${(p) => p.theme.depth.front + 2};
  ${(p) =>
    p.isLoading &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`
const LoadingSpinner = styled(e.Loading.Spinner)`
  transform: scale(0.5);
  color: ${(p) => p.theme.color.lightGrey};
`

// type OuterProps = IInput & FieldProps;
export default abstract class InputComponent<
  P = Record<string, unknown>,
  S = Record<string, unknown>
> extends React.Component<
  InputInternalProps<P>,
  S & {
    isTooltipShowing: boolean
    isErrorShowing: boolean
    isFocused: boolean
  }
> {
  public static defaultProps = {
    onChange: () => null,
    defaultErrorMessage: 'please enter a valid value',
  }

  protected manuallySetFocusedAndDirty = false

  constructor(props: any) {
    super(props)
    this.state = {
      ...this.state,
      isFocused: false,
      isTooltipShowing: false,
      isErrorShowing: false,
    }
  }

  protected get name(): string {
    const { name } = this.props
    return name
  }

  protected get value(): string {
    const {
      formik: { values },
    } = this.props

    return access(values, this.name)
  }

  protected get isEmpty(): boolean {
    return !this.value
  }

  protected get isValid(): boolean {
    return !this.errorMessage
  }

  protected get isDirty(): boolean {
    const {
      formik: { touched },
    } = this.props

    return access(touched, this.name)
  }

  protected get isPristine(): boolean {
    return !this.isDirty
  }

  protected get isFocused(): boolean {
    const { isFocused } = this.state
    return isFocused
  }

  protected get errorMessage(): string {
    const {
      formik: { errors },
    } = this.props
    return access(errors, this.name)
  }

  // eslint-disable-next-line class-methods-use-this
  protected get isDebounced(): boolean {
    return true
  }

  public componentDidUpdate = (prevProps) => {
    const { formik } = this.props
    if (formik !== prevProps.formik) {
      if (!this.isValid && this.isDirty) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isErrorShowing: true,
        } as any)
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isErrorShowing: false,
        } as any)
      }
    }
  }

  public componentDidMount = () => {
    const {
      formik: { setFieldTouched },
    } = this.props

    if (this.value) setFieldTouched(this.name, true)
  }

  protected abstract renderInput: () => JSX.Element

  public render = () => {
    const { isTooltipShowing, isErrorShowing } = this.state
    const {
      isLoading,
      label,
      iconEnd,
      isLabelOpaque,
      type,
      button,
      iconMessage,
      formik,
      name,
    } = this.props

    return (
      <InputContainer
        onBlur={() => {
          if (!this.manuallySetFocusedAndDirty) {
            this.setState({ isFocused: false } as any)
            formik.setFieldTouched(this.name, true)
          }
          void trackEvent('input.blur', {
            name: this.name,
            value: type === 'password' ? 'hidden' : this.value,
          })
        }}
        onFocus={() => {
          if (!this.manuallySetFocusedAndDirty) {
            this.setState({ isFocused: true } as any)
          }
          // trackEvent(`input.focus`, { name: this.name, value: this.value });
        }}
      >
        <e.Tooltip.default
          disabled={!iconMessage}
          open={isTooltipShowing}
          position='bottom'
          title={iconMessage}
        >
          <>
            <c.LabelText
              htmlFor={name}
              isOpaque={!!isLabelOpaque}
              isShowing={!this.isEmpty}
            >
              {label}
            </c.LabelText>
            {this.renderInput()}
            {isErrorShowing && <c.ErrorMessage name={this.name} />}
            {iconEnd && (
              <e.Tooltip.default
                onHide={() => this.setState({ isTooltipShowing: false } as any)}
                onShow={() => this.setState({ isTooltipShowing: true } as any)}
                open={false}
              >
                <>{iconEnd}</>
              </e.Tooltip.default>
            )}
            {button}
            {isLoading !== undefined && (
              <LoadingContainer isLoading={isLoading}>
                <LoadingSpinner />
              </LoadingContainer>
            )}
          </>
        </e.Tooltip.default>
      </InputContainer>
    )
  }
}
