export enum AddressType {
  Billing = 'BILLING',
  Delivery = 'DELIVERY',
}

export type IAddress = {
  readonly firstName: string
  readonly lastName: string
  readonly line1: string
  readonly line2?: string
  readonly town: string
  readonly county?: string
  readonly postcode: string
}

export interface ICheckoutForm {
  readonly firstName: string
  readonly lastName?: string
  readonly password?: string
  readonly email?: string
  readonly phoneNo?: string
  readonly specialInstructions?: string
  readonly deliveryDate?: string
  readonly deliveryAddress?: IAddress
  readonly nameOnCard?: string
  readonly useAddressType?: AddressType
  readonly billingAddress?: IAddress
  readonly acceptsMarketing?: boolean
  readonly acceptsMarketingSms?: boolean
  readonly acceptsMarketingPost?: boolean
  readonly acceptsThirdParty?: boolean
}
