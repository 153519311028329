import * as React from 'react'

import { Map } from 'immutable'
import omit from 'lodash/omit'

import config from '@fe/config'
import useAuthService from '@fe/hooks/Auth/useService'
import {
  ICheckoutForm,
  IAddress,
  AddressType,
} from '@fe/providers/checkoutFormData.d'
import { sendEvent } from '@fe/services/gtm'
import getCookieManager from '@fe/utils/cookies'

const ignoreUndefinedAndNull = (o, s) => (s === undefined || s === null ? o : s)
const nullAddress = (): IAddress => ({
  postcode: '',
  firstName: '',
  lastName: '',
  line1: '',
  line2: '',
  town: '',
  county: '',
})
const createBlankForm = (): ICheckoutForm => ({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNo: '',
  specialInstructions: '',
  deliveryDate: '',
  deliveryAddress: nullAddress(),
  nameOnCard: '',
  billingAddress: nullAddress(),
  useAddressType: AddressType.Delivery,
  acceptsMarketing: true,
  acceptsMarketingSms: true,
  acceptsMarketingPost: true,
  acceptsThirdParty: true,
})
const blankForm = Map({ ...createBlankForm() })

const context = React.createContext<{
  form: ICheckoutForm
  update: (data: Partial<ICheckoutForm>) => void
}>({
  form: blankForm.toJS(),
} as any)

export function useCheckoutFormDataService() {
  const cookies = getCookieManager()
  const { userDetails } = useAuthService()

  const savedFormText = cookies.get(config.cookieKey)
  const savedFormJson = savedFormText
    ? JSON.parse(decodeURIComponent(savedFormText))
    : {}
  const savedForm = savedFormJson.checkoutForm || savedFormJson
  const loggedInFormData = {
    email: userDetails.email,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
  }
  const formFromInitialisingData = blankForm.mergeDeepWith(
    ignoreUndefinedAndNull,
    savedForm,
    loggedInFormData
  )
  const [form, setForm] = React.useState(formFromInitialisingData)

  const update = React.useCallback(
    (newData: Partial<ICheckoutForm>) => {
      setForm((prevForm) => {
        const updatedForm = prevForm.mergeDeepWith(
          ignoreUndefinedAndNull,
          newData
        )
        const formDataFiltered = omit(updatedForm.toJS(), [
          'password',
          'deliveryDate',
          'cardCvc',
          'cardNumber',
          'cardExpiry',
        ])

        void sendEvent('checkoutForm', { form: formDataFiltered })
        cookies.merge(config.cookieKey, formDataFiltered)
        return updatedForm
      })
    },
    [cookies]
  )
  return {
    update,
    form: form.toJS() as ICheckoutForm,
  }
}

export function CheckoutFormDataProvider({ children }) {
  const service = useCheckoutFormDataService()
  return <context.Provider value={service}>{children}</context.Provider>
}

export default function useCheckoutFormData() {
  const checkoutFormData = React.useContext(context)
  if (!checkoutFormData)
    throw new Error(
      'component must be a child element of the CheckoutFormDataProvider'
    )
  return checkoutFormData
}
