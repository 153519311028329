import * as React from 'react'

import DeliveryVanIcon from '@fe/components/icons/DeliveryVan'
import SearchIcon from '@fe/components/icons/Search'
import SiteDownIcon from '@fe/components/icons/SiteDown'
import WarningIcon from '@fe/components/icons/Warning'
import HamburgerIcon from '@fe/components/icons/hamburger'
import PeoplePlanetIcon from '@fe/components/icons/peoplePlanet'
import AccountIcon from '@fe/components/images/allplants/account'
import ArrowIcon from '@fe/components/images/allplants/arrow'
import CalendarIcon from '@fe/components/images/allplants/calendar'
import CardIcon from '@fe/components/images/allplants/card'
import CrossIcon from '@fe/components/images/allplants/cross'
import InfoIcon from '@fe/components/images/allplants/info'
import PasswordIcon from '@fe/components/images/allplants/password'
import PromiseIcon from '@fe/components/images/allplants/promise'
import QuestionIcon from '@fe/components/images/allplants/question'
import TickIcon from '@fe/components/images/allplants/tick'
import VanIcon from '@fe/components/images/allplants/van'
import PaymentsInlineLogo from '@fe/components/logos/paymentTypes/all-inline'
import ApplePayLogo from '@fe/components/logos/paymentTypes/apple-pay'
import CardsInlineLogos from '@fe/components/logos/paymentTypes/card-inline'
import GoolgePayLogo from '@fe/components/logos/paymentTypes/google-pay'
import MicrosoftPayLogo from '@fe/components/logos/paymentTypes/ms-wallet'
import PaymentRequestInlineLogos from '@fe/components/logos/paymentTypes/payment-request-inline'
import styled, { css } from '@fe/styles'

const DinersIconSrc = 'logos/paymentTypes/dark/diners.png'
const DiscoverIconSrc = 'logos/paymentTypes/dark/discover.png'
const JcbIconSrc = 'logos/paymentTypes/dark/jcb.png'
const MastercardIconSrc = 'logos/paymentTypes/dark/mastercard.png'
const VisaIconSrc = 'logos/paymentTypes/dark/visa.png'
const AmexIconSrc = 'logos/paymentTypes/dark/amex.png'

export const Warning = styled(WarningIcon)`
  width: 16px;
  height: 16px;
`
export const SiteDown = styled(SiteDownIcon)`
  width: 24px;
  height: 24px;
`

export const Hamburger = styled(HamburgerIcon)`
  width: 30px;
  height: 20px;
  color: black;
  cursor: pointer;
`
export const DeliveryVan = styled(DeliveryVanIcon)`
  width: 25px;
  height: 25px;
`

export const Tick = styled(TickIcon)`
  width: 25px;
  height: 21px;
`
export const Cross = styled(CrossIcon)`
  width: 30px;
  height: 31px;
`
export const Info = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.color.lightGrey};
`
export const Calendar = styled(CalendarIcon)`
  width: 18px;
  height: 21px;
`

export const PeoplePlanet = styled(PeoplePlanetIcon)`
  width: 66px;
  height: 66px;
  color: black;
`

export const Search = styled(SearchIcon)`
  width: 25px;
  height: 25px;
`

const directionDegrees = {
  left: '90deg',
  right: '270deg',
  up: '-180deg',
  down: '0deg',
}
export interface IArrow {
  direction?: 'left' | 'right' | 'up' | 'down'
  disableAnimation?: boolean
  isOpened?: boolean
}
export const ArrowContainer = styled.div<IArrow>`
  color: black;
  width: 22px;
  height: 13px;
  svg {
    ${(p) =>
      !p.disableAnimation &&
      css`
        transition: transform 0.4s ease-out;
      `};
    transform: ${(p) => (p.isOpened ? 'rotate(180deg);' : 'rotate(0deg)')};
    transform: ${(p) => `rotate(${directionDegrees[p.direction || 'down']})`};
  }
`

export const Arrow: React.SFC<IArrow> = (props) => (
  <ArrowContainer {...props}>
    <ArrowIcon />
  </ArrowContainer>
)

const PaymentLogo = styled.img`
  width: 24px;
  height: 16px;
`
export const Diners = (props) => <PaymentLogo {...props} src={DinersIconSrc} />
export const Discover = (props) => (
  <PaymentLogo {...props} src={DiscoverIconSrc} />
)
export const Jcb = (props) => <PaymentLogo {...props} src={JcbIconSrc} />
export const Mastercard = (props) => (
  <PaymentLogo {...props} src={MastercardIconSrc} />
)
export const Amex = (props) => <PaymentLogo {...props} src={AmexIconSrc} />
export const Visa = (props) => <PaymentLogo {...props} src={VisaIconSrc} />
export const PaymentsInline = styled(PaymentsInlineLogo)`
  width: 100%;
  max-width: 500px;
`
export const CardIconsInline = styled(CardsInlineLogos)`
  height: 100%;
  max-height: 26px;
`
export const PaymentRequestsInline = styled(PaymentRequestInlineLogos)`
  height: 100%;
  max-height: 26px;
`
export const GooglePay = styled(GoolgePayLogo)`
  height: 22px;
`
export const ApplePay = styled(ApplePayLogo)`
  height: 22px;
`
export const MicrosoftPay = styled(MicrosoftPayLogo)`
  height: 22px;
`

export {
  AccountIcon,
  PasswordIcon,
  VanIcon,
  CardIcon,
  QuestionIcon,
  PromiseIcon,
}
