import styled, { css } from '@fe/styles'

export const Simple = styled.input<{
  isDirty?: boolean
  isValid?: boolean
}>`
  padding: 0 10px;
  height: 56px;
  line-height: 56px;
  border: 2px solid ${(p) => p.theme.color.lightGrey};
  background: white;
  transition: ${(p) => p.theme.transition.default};
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      background: rgb(240, 240, 240);
      opacity: 0.6;
    `}
  ${(p) =>
    p.isDirty &&
    !p.isValid &&
    css`
      padding: 0;
      border-width: 2px;
      border-color: ${p.theme.color.red};
    `}
  :focused {
    padding: 0;
    border-width: 2px;
    border-color: ${(p) => p.theme.color.yellow};
  }
  outline: none;
  width: 100%;
  font-family: inherit;
  font-size: 18px;
  font-weight: normal;
  color: ${(p) => p.theme.color.input.default};
  ::placeholder {
    color: ${(p) => p.theme.color.input.placeholder};
  }
`

export default Simple
