export default function createPromiseWithTimeout(
  cb,
  timeoutMs,
  silentTimeout = false
) {
  return new Promise((resolve, reject) => {
    let returned = false
    const timeoutHandle = setTimeout(() => {
      if (!returned) {
        returned = true
        if (silentTimeout) resolve()
        else reject()
      }
    }, timeoutMs)
    cb(() => {
      if (!returned) {
        returned = true
        clearTimeout(timeoutHandle)
        resolve()
      }
    })
  })
}
