import * as React from 'react'

import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { useRouter } from 'next/router'

import config from '@fe/config'
import useCart from '@fe/providers/cart'
import { getDeliveryDates } from '@fe/services/allplants'
import { trackEvent } from '@fe/services/analytics'

import useCheckoutFormData from './checkoutFormData'

const MIN_DAYS_BEFORE_ORDER = 2

function ensureUtcDate(day: moment.Moment) {
  if (!day) return day
  if (day.isUtc()) return day
  return moment.utc(day.format('DD-MM-YYYY'), 'DD-MM-YYYY')
}

export function defaultIsDayBlocked(isTrial: boolean) {
  return (dayToCheck: moment.Moment): boolean => {
    const utcDayToCheck = ensureUtcDate(dayToCheck)
    const isAfter13 = moment().isAfter(13, 'h')

    const min = isAfter13 ? moment.utc().add(3, 'd') : moment.utc().add(2, 'd')
    const max = moment.utc().add(28, 'd')

    if (utcDayToCheck.isBefore(min, 'day')) return true
    if (utcDayToCheck.isAfter(max, 'day')) return true
    if (isTrial) return [1, 2, 3].includes(utcDayToCheck.day())
    return [1, 0].includes(utcDayToCheck.day())
  }
}
export function defaultIsDayHighlighted(dayToCheck: moment.Moment): boolean {
  const utcDayToCheck = ensureUtcDate(dayToCheck)
  if ([0, 6].includes(utcDayToCheck.day())) return true
  return false
}

function createIsDayBlocked(deliveryOptions, isTrial: boolean) {
  return function isDayBlocked(dayToCheck: moment.Moment) {
    const utcDayToCheck = ensureUtcDate(dayToCheck)
    // If we didn't get any delivery dates from the API, then hard exclude the first 2 days.
    if (!deliveryOptions && MIN_DAYS_BEFORE_ORDER) {
      const globalMinDay = moment.utc().add(MIN_DAYS_BEFORE_ORDER, 'd')
      if (utcDayToCheck.isBefore(globalMinDay, 'day')) return true
    }

    const minDay = moment.utc(deliveryOptions.min!, 'DD-MM-YYYY')
    if (utcDayToCheck.isBefore(minDay, 'day')) return true
    const maxDay = moment.utc(deliveryOptions.max!, 'DD-MM-YYYY')
    if (utcDayToCheck.isAfter(maxDay, 'day')) return true
    if (isTrial && utcDayToCheck.day() === 2) return true
    if (deliveryOptions.excludedDates) {
      return deliveryOptions.excludedDates.some((excludedDateString) => {
        const excludedDate = moment.utc(excludedDateString!, 'DD-MM-YYYY')
        return utcDayToCheck.isSame(excludedDate, 'day')
      })
    }
    return false
  }
}
function createIsDayHighlighted() {
  return function isDayHighlighted(dayToCheck: moment.Moment) {
    const utcDayToCheck = ensureUtcDate(dayToCheck)
    if (!utcDayToCheck) return false
    return defaultIsDayHighlighted(utcDayToCheck)
  }
}
function getFirstNonHighlightedDeliveryDate(
  isDayBlocked,
  isDayHighlighted,
  minDate?: string
) {
  const startDate = minDate
    ? moment.utc(minDate, 'DD-MM-YYYY')
    : moment.utc().startOf('d')
  const dayToCheck = startDate
  const maxCount = 20
  let currentCount = 0
  while (
    (isDayBlocked(dayToCheck) || isDayHighlighted(dayToCheck)) &&
    currentCount < maxCount
  ) {
    dayToCheck.add(1, 'days')
    currentCount += 1
  }
  return maxCount === currentCount ? undefined : dayToCheck
}
function trackDeliveryDatesAvailable(
  deliveryData,
  getIsDayBlocked,
  getIsDayHighlighted,
  recommendedDate: moment.Moment | undefined
) {
  if (!recommendedDate) return
  const today = moment.utc().startOf('d')
  const startDate = deliveryData.min
    ? moment.utc(deliveryData.min, 'DD-MM-YYYY')
    : moment.utc().startOf('d')
  const endDate = deliveryData.max
    ? moment.utc(deliveryData.max, 'DD-MM-YYYY')
    : startDate.add(40, 'd')
  const maxCount = Math.min(endDate.diff(startDate, 'd'), 40)
  let currentCount = 0
  let weekdayCount = 0
  let weekendCount = 0
  let daysToFirstDeliverySlot
  while (currentCount < maxCount) {
    const dayToCheck = moment.utc().add(currentCount, 'd')
    if (!getIsDayBlocked(dayToCheck)) {
      if (daysToFirstDeliverySlot === undefined)
        daysToFirstDeliverySlot = currentCount
      if (getIsDayHighlighted(dayToCheck)) weekdayCount += 1
      else weekendCount += 1
    }
    currentCount += 1
  }

  void trackEvent('delivery.dates.received', {
    daysToFirstDeliverySlot,
    weekdayCount,
    weekendCount,
    totalCount: weekdayCount + weekendCount,
    daysToRecommendedDeliverySlot: recommendedDate
      ? recommendedDate.diff(today, 'd')
      : undefined,
    recommendedDate: recommendedDate && recommendedDate.format('DD-MM-YYYY'),
    recommendedDay: recommendedDate && recommendedDate.format('dddd'),
  })
}

const recordIfIncorrectDeliveryDateSelected = (dayToCheck: moment.Moment) => {
  if (!dayToCheck) return
  const weekday: number = moment(dayToCheck).weekday()
  if (weekday === 1) {
    Sentry.addBreadcrumb({
      data: {
        deliveryDay: dayToCheck,
        weekDay: weekday,
      },
      message: 'Unavailable delivery day selected.',
      level: Sentry.Severity.Info,
    })
    Sentry.captureMessage(
      'Unavailable delivery day selected.',
      Sentry.Severity.Error
    )
  }
}

interface IDeliveryDate {
  date: string
  cost: number
  isHighlighted?: boolean
}

interface IDeliveryDaysContext {
  isDayHighlighted: (dayToCheck: moment.Moment) => boolean
  isDayBlocked: (dayToCheck: moment.Moment) => boolean
  selectDeliveryDate: (date: moment.Moment) => void
  deliveryDate?: IDeliveryDate
  suggestedDeliveryDate?: moment.Moment
}

const context = React.createContext<IDeliveryDaysContext>({
  isDayHighlighted: defaultIsDayHighlighted,
  isDayBlocked: defaultIsDayBlocked(false),
  selectDeliveryDate: () => undefined,
})

export function DeliveryDaysProvider({ children }) {
  const {
    query: { t },
  } = useRouter()
  const { cart } = useCart()
  const isTrial = t === '1'
  const skus = cart?.skus
  const [deliveryDates, setDeliveryDates] = React.useState(null as any)

  // We are guessing the date and cost from the form here
  // which is brittle and not guaranteed to be correct/defined
  // We really should be receiving this information from the
  // backend in the cart object.
  const formDateString = useCheckoutFormData().form.deliveryDate

  const [deliveryDate, setDeliveryDate] = React.useState<
    IDeliveryDate | undefined
  >({
    date: '',
    cost: 0,
    isHighlighted: false,
  })

  React.useEffect(() => {
    if (skus && !config.featureToggle.disableBlueprintRequests) {
      getDeliveryDates(skus).then((d) => {
        setDeliveryDates(d.data[0])
      })
    }
  }, [skus])

  let value: Omit<IDeliveryDaysContext, 'selectDeliveryDate'> = {
    deliveryDate,
    isDayBlocked: defaultIsDayBlocked(isTrial),
    isDayHighlighted: defaultIsDayHighlighted,
    suggestedDeliveryDate: getFirstNonHighlightedDeliveryDate(
      defaultIsDayBlocked(isTrial),
      defaultIsDayHighlighted
    ),
  }

  if (deliveryDates) {
    const isDayBlocked = createIsDayBlocked(deliveryDates, isTrial)
    const isDayHighlighted = createIsDayHighlighted()
    const suggestedDeliveryDate = getFirstNonHighlightedDeliveryDate(
      isDayBlocked,
      isDayHighlighted,
      deliveryDates.min
    )
    value = {
      suggestedDeliveryDate,
      isDayBlocked: createIsDayBlocked(deliveryDates, isTrial),
      isDayHighlighted: createIsDayHighlighted(),
    }
  }
  React.useEffect(() => {
    if (deliveryDates) {
      trackDeliveryDatesAvailable(
        deliveryDates,
        value.isDayBlocked,
        value.isDayHighlighted,
        value.suggestedDeliveryDate
      )
    }
  }, [deliveryDates])

  const selectDeliveryDate = React.useCallback(
    (date: moment.Moment) => {
      recordIfIncorrectDeliveryDateSelected(date)

      const isHighlighted = value.isDayHighlighted(date)
      const stringDate = date.toISOString()
      setDeliveryDate({
        isHighlighted,
        date: stringDate,
        cost: isHighlighted ? 250 : 0,
      })
    },
    [value, setDeliveryDate]
  )
  React.useEffect(() => {
    if (formDateString && deliveryDate?.date !== formDateString) {
      selectDeliveryDate(moment.utc(formDateString))
    }
  }, [selectDeliveryDate, deliveryDate, formDateString])

  return (
    <context.Provider value={{ ...value, selectDeliveryDate, deliveryDate }}>
      {children}
    </context.Provider>
  )
}

export default function useDeliveryDaysManager() {
  const deliveryDaysManager = React.useContext(context)

  return deliveryDaysManager
}
