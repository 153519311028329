import * as React from 'react'

import { Portal as ReactPortal } from 'react-portal'

import styled, { css, defaults } from '@fe/styles'

const SpinkitDots = styled.div<{
  color?: string
}>`
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => color || 'currentColor'};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`
const SpinkitContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 13px;
  ${SpinkitDots}:nth-child(1) {
    left: 8px;
    animation: ellipsis-start 0.6s infinite;
  }
  ${SpinkitDots}:nth-child(2) {
    left: 8px;
    animation: ellipsis-middle 0.6s infinite;
  }
  ${SpinkitDots}:nth-child(3) {
    left: 32px;
    animation: ellipsis-middle 0.6s infinite;
  }
  ${SpinkitDots}:nth-child(4) {
    left: 56px;
    animation: ellipsis-end 0.6s infinite;
  }
  @keyframes ellipsis-start {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis-end {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis-middle {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
const Spinkit = ({ color, ...rest }) => (
  <SpinkitContainer {...rest}>
    <SpinkitDots color={color} />
    <SpinkitDots color={color} />
    <SpinkitDots color={color} />
    <SpinkitDots color={color} />
  </SpinkitContainer>
)
const Overlay = styled.div<{
  isShowing?: boolean
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.color.overlay};
  z-index: ${(p) => p.theme.depth.front};
  opacity: 0;
  pointer-events: none;
  ${(p) =>
    p.isShowing &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`
const ContentContainer = styled.div`
  ${(p) => p.theme.layout.paddingResizeCss};
  text-align: center;
`
const LoadingMessage = styled.div<{
  color?: string
  isShowing?: boolean
}>`
  margin-top: 10px;
  transition: ${(p) => p.theme.transition.default};
  opacity: 0;
  color: ${(p) => p.color || 'black'};
  ${(p) =>
    p.isShowing &&
    css`
      transition-delay: 1s;
      opacity: 1;
    `}
`
const SpinnerContainer = styled.div`
  min-height: 25px;
  min-width: 60px;
  text-align: center;
`

export const Spinner: React.SFC<{
  className?: string
  color?: string
}> = ({ className, color, children, ...rest }) => (
  <SpinnerContainer className={className}>
    <Spinkit color={color || 'currentColor'} {...rest} />
    {children && (
      <LoadingMessage color={color || 'currentColor'} isShowing>
        {children}
      </LoadingMessage>
    )}
  </SpinnerContainer>
)
export const Screen: React.SFC<{
  isLoading?: boolean
  message?: string
  id?: string
}> = ({ isLoading, message, id }) => (
  <ReactPortal>
    <Overlay id={id} isShowing={isLoading}>
      <ContentContainer>
        <Spinner color='white' />
        <LoadingMessage color='white' isShowing={isLoading}>
          {message}
        </LoadingMessage>
      </ContentContainer>
    </Overlay>
  </ReactPortal>
)

const ContainedContentContainer = styled.div<{
  isLoading: boolean
}>`
  min-height: 120px;
  position: relative;
  ${(p) =>
    p.isLoading &&
    css`
      pointer-events: none;
    `}
`
const ContainedOverlay = styled.div<{
  isLoading: boolean
  color: string
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  background: ${(p) => p.color};
  z-index: ${(p) => p.theme.depth.foreground};
  color: black;
  transition: ${(p) => p.theme.transition.default};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) =>
    p.isLoading &&
    css`
      opacity: 1;
    `}
`
export const Contained: React.FC<{
  children?: JSX.Element
  isLoading: boolean
  message?: string
  color?: string
  background?: string
  className?: string
}> = ({
  isLoading,
  message,
  color = defaults.color.text,
  background = 'rgba(255,255,255,0.8)',
  children,
  className,
}) => (
  <ContainedContentContainer className={className} isLoading={isLoading}>
    {children}
    <ContainedOverlay color={background} isLoading={isLoading}>
      <ContentContainer>
        <Spinner color={color} />
        <LoadingMessage color={color} isShowing={isLoading}>
          {message}
        </LoadingMessage>
      </ContentContainer>
    </ContainedOverlay>
  </ContainedContentContainer>
)
