import * as React from 'react'

import { OrderSummary } from '@fe/types/orderSummary'

import useOrderSummaryService from './useService'

const DefaultOrderSummary = {
  deliveryCost: 0,
  total: 0,
  totalDiscount: 0,
  discount: undefined,
}
const context = React.createContext<OrderSummary>(DefaultOrderSummary)

export function OrderSummaryProvider({ children }) {
  const service = useOrderSummaryService() || DefaultOrderSummary

  return <context.Provider value={service}>{children}</context.Provider>
}
export function useOrderSummary(): OrderSummary {
  const service = React.useContext(context)
  if (service === undefined)
    throw new Error(
      'useOrderSummary can only be used inside a OrderSummaryProvider'
    )
  return service
}
