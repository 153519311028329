import * as React from 'react'

export default function Twitter(props) {
  return (
    <svg
      {...props}
      version='1.1'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Twitter</title>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='currentColor' id='Twitter'>
          <path
            d='M29.2390948,6.39984351 C28.0599059,7.00371674 26.8864273,7.41314554 25.7186591,7.64319249 C24.6608153,6.55594679 23.3217364,6 21.7256913,6 C20.1581981,6 18.840533,6.52719092 17.7627029,7.5528169 C16.6863004,8.58528951 16.145244,9.83411581 16.145244,11.2965571 C16.145244,11.7456964 16.2037752,12.1400626 16.3194099,12.4755477 C11.7097235,12.3276604 7.889494,10.4886541 4.88156295,6.9557903 C4.36905832,7.81846635 4.12208534,8.70442097 4.12208534,9.60954617 C4.12208534,11.4841549 4.94294929,12.9671362 6.58182203,14.0571205 C5.64532332,13.9804382 4.82588696,13.7572379 4.12208534,13.3820423 C4.12208534,14.7171362 4.52323798,15.8331377 5.35123989,16.8176839 C6.16924866,17.7857981 7.22709248,18.4129499 8.52334373,18.6703834 C8.0522392,18.7922535 7.55543806,18.8388106 7.05007138,18.8388106 C6.58182203,18.8388106 6.25062127,18.8059468 6.05218633,18.7196792 C6.39766298,19.8082942 7.05007138,20.6942488 7.99513562,21.3665884 C8.93163433,22.0485133 10.0066092,22.4086463 11.2157775,22.4428795 C9.22286258,23.94777 6.94014699,24.6913146 4.35621001,24.6913146 C3.7195051,24.6913146 3.27266959,24.676252 3,24.6351721 C5.54824724,26.2153756 8.37487442,27 11.5112885,27 C14.7247925,27 17.5599852,26.2222222 20.0168667,24.6611894 C22.4865965,23.1165884 24.3096283,21.2008998 25.4831069,18.9524648 C26.6523026,16.6985524 27.2376143,14.3597418 27.2376143,11.9141236 L27.2376143,11.2404147 C28.3739756,10.4188185 29.2961984,9.49863067 30,8.47300469 C28.9749907,8.89475743 27.9200021,9.18231612 26.8278962,9.33020344 C28.04563,8.61267606 28.8493629,7.64319249 29.2390948,6.39984351 Z'
            id='Fill-1'
          />
        </g>
      </g>
    </svg>
  )
}
