import * as React from 'react'

import Link from 'next/link'

import styled from '@fe/styles'

/**
 * A way to create universal buttons that return the correct component based on whether it is
 * an absolute link, relative link, or on click
 * @param ButtonComponent The base style of button component if it needs custom styling
 * @param LinkComponent The base style of link component if it needs custom styling
 * @param AnchorComponent The base style of anchor component if it needs custom styling
 */
const createUniversalButton =
  (
    {
      ButtonComponent = styled.button``,
      LinkComponent = Link,
      AnchorComponent = styled.a``,
    } = {
      ButtonComponent: styled.button``,
      LinkComponent: Link,
      AnchorComponent: styled.a``,
    }
  ) =>
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isNotDecorated,
    to,
    onClick,
    useSubmit,
    ...props
  }: {
    isNotDecorated?: boolean
    to?: string
    onClick?: () => void
    useSubmit?: boolean
  } & HTMLElement &
    (HTMLButtonElement | HTMLAnchorElement) &
    any) => {
    if (!useSubmit && !to && !onClick) {
      // eslint-disable-next-line no-console
      console.warn(
        'set "useSubmit" to true if not specifying "to" or "onClick" property'
      )
      return <ButtonComponent {...props} />
    }
    if (to) {
      if (to.startsWith('/'))
        return <LinkComponent onClick={onClick} to={to} {...props} />
      return <AnchorComponent href={to} onClick={onClick} {...props} />
    }
    return <ButtonComponent onClick={onClick} {...props} />
  }
// eslint-disable-next-line import/prefer-default-export
export const DefaultUniversalButton = createUniversalButton()
