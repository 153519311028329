import * as React from 'react'

import useCart from '@fe/providers/cart'
import { Discount } from '@fe/types/allplants'

import useTryDiscount from './useTryDiscount'

const context = React.createContext<{
  appliedDiscount?: Discount
  isDiscountCodeOpen: boolean
  successMessage: string
  setIsDiscountCodeOpen: (isOpen: boolean) => void
  isTryingDiscount: boolean
  tryApplyDiscount: (
    token: string,
    code: string,
    cartId?: number
  ) => Promise<boolean | null>
  tryRemoveDiscount: (token: string, cartId?: number) => Promise<boolean | null>
  setSuccessMessage: (message: string) => void
}>({
  isDiscountCodeOpen: false,
  successMessage: '',
  setIsDiscountCodeOpen: () => undefined,
  isTryingDiscount: false,
  tryApplyDiscount: async () => false,
  tryRemoveDiscount: async () => false,
  setSuccessMessage: () => undefined,
})

function useDiscountService() {
  const [isDiscountCodeOpen, setIsDiscountCodeOpen] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const { isTryingDiscount, tryApplyDiscount, tryRemoveDiscount } =
    useTryDiscount()

  const appliedDiscount = useCart().cart?.discountCodes?.[0]

  return {
    appliedDiscount,
    isDiscountCodeOpen,
    successMessage,
    setIsDiscountCodeOpen,
    isTryingDiscount,
    tryApplyDiscount,
    tryRemoveDiscount,
    setSuccessMessage,
  }
}

export function DiscountProvider({ children }) {
  const service = useDiscountService()

  return <context.Provider value={service}>{children}</context.Provider>
}
export function useDiscountManager() {
  const consumer = React.useContext(context)
  if (!consumer)
    throw new Error('useDiscount can only be used inside a discount provider')
  return consumer
}
