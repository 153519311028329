// Export the `styled-component` lib with theme definitions

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import { ComponentClass } from 'react'

import * as styledComponents from 'styled-components'

/* Local */
import defaultTheme from './themes/default'

// ----------------------------------------------------------------------------

export interface IClassNameProps {
  className: string
}

const { default: styled } =
  styledComponents as styledComponents.ThemedStyledComponentsModule<
    typeof defaultTheme
  >

const css = styledComponents.css as styledComponents.ThemedCssFunction<
  typeof defaultTheme
>
const { createGlobalStyle } = styledComponents
const { keyframes } = styledComponents
const ThemeProvider = styledComponents.ThemeProvider as ComponentClass<
  styledComponents.ThemeProviderProps<typeof defaultTheme>
>

export {
  css,
  keyframes,
  createGlobalStyle,
  ThemeProvider,
  defaultTheme as defaults,
}

export default styled
