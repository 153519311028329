import * as React from 'react'

const Hamburger: React.SFC<{
  color: string
}> = ({ color, ...rest }) => (
  <svg
    {...rest}
    version='1.1'
    viewBox='0 0 30 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>hamburger</title>
    <g
      fill='none'
      fillRule='evenodd'
      id='Homepage'
      stroke='none'
      strokeWidth='1'
    >
      <g
        fill={color}
        fillRule='nonzero'
        id='icon/hamburger'
        transform='translate(-9.000000, -14.000000)'
      >
        <path
          d='M9,14 L39,14 L39,17 L9,17 L9,14 Z M9,22 L36,22 L36,25 L9,25 L9,22 Z M9,31 L27,31 L27,34 L9,34 L9,31 Z'
          id='Combined-Shape'
        />
      </g>
    </g>
  </svg>
)

export default Hamburger
