/* eslint-disable camelcase */
import config from '@fe/config'
import createPromiseWithTimeout from '@fe/utils/createPromiseWithTimeout'

/**
 * This library sends events through google tag manager
 * @method Send methods create a new event for any trigger
 * automatically passed on to the analytics platforms (e.g. Amplitude)
 * with the correct name and data
 */

export const addData = (content: any) => {
  try {
    return createPromiseWithTimeout(
      (cb) =>
        // eslint-disable-next-line no-undef
        dataLayer.push({
          // This clears data to prevent some merging in SPAs
          // https://www.simoahava.com/analytics/two-simple-data-model-tricks/
          _clear: true,
          ...content,
          eventCallback: () => {
            if (content.eventCallback) content.eventCallback()
            cb()
          },
        }),
      1000,
      true
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to add data for GTM:', e)
    return Promise.resolve()
  }
}
export const sendEvent = (eventName: string, content: any = {}) => {
  try {
    const data = {
      ...content,
      event: eventName,
    }
    return addData(data)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to send event ${eventName} for GTM:`, e)
    return Promise.resolve()
  }
}
const sentEvents = {}
export const sendEventOncePerSession = (eventName, ...rest) => {
  if (!sentEvents[eventName]) {
    sentEvents[eventName] = true
    return sendEvent(eventName, ...rest)
  }
  return Promise.resolve()
}
export const sendEcommerce = (eventName, data) =>
  Promise.all([
    sendEvent(eventName, data),
    // Clear the ecommerce so it doesn't get sent with other GA events
    // (`_clear: true` only prevents merging, it doesn't remove the object
    // after pushing to the data layer)
    addData({ ecommerce: undefined }),
  ])

export const sendMarketingEvent = (eventName, data) =>
  sendEvent('marketing', {
    marketing_event_name: eventName,
    marketing_event_data: data,
  })

export interface IRevenueData {
  quantity: number
  price: number
  type: string
  productId: string
  eventProperties?: any
}
export const trackRevenue = (data: Array<IRevenueData>) =>
  Promise.all(
    data.map((item) =>
      sendEvent('revenue', {
        revenue_data: item,
      })
    )
  )

export const GoogleTagManagerScripts = () => (
  <>
    {/* <!-- Google Tag Manager --> */}
    <script>{`
      window.dataLayer=window.dataLayer||[];
    `}</script>
    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${config.analytics.gtm.id}');`,
      }}
      defer
      type='text/javascript'
    />
    <noscript>{`
      <iframe src='https://www.googletagmanager.com/ns.html?id=${config.analytics.gtm.id}'
      height='0' width='0' style='display:none;visibility:hidden'></iframe>
    `}</noscript>
    {/* <!-- End Google Tag Manager --> */}
  </>
)

export const GoogleTagManagerConsentDefaultScript = () => (
  <script id='consent-default'>
    {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied',
      });`}
  </script>
)
