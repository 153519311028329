import * as React from 'react'

type TIdentityContext = {
  isAnonymousUserIdentified: boolean
  setAnonymousUserIdentified: () => void
}

const IdentityContext = React.createContext<TIdentityContext | undefined>(
  undefined
)

function IdentityProvider({ children }) {
  const [isAnonymousUserIdentified, update] = React.useState<boolean>(false)

  return (
    <IdentityContext.Provider
      value={{
        isAnonymousUserIdentified,
        setAnonymousUserIdentified: () => update(true),
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}

function useIdentity() {
  const context = React.useContext(IdentityContext)
  if (context === undefined) {
    throw new Error('useIdentity must be used within an IdentityProvider')
  }
  return context
}

export { IdentityProvider, useIdentity }
