import {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  ReactElement,
} from 'react'

import noop from 'lodash/noop'

export type TUserProps = {
  firstName?: string
  lastName?: string
  email?: string
  setUserDetails?: typeof noop
}

export type TUserProviderProps = PropsWithChildren<TUserProps>

type TUserProvider = (props: TUserProviderProps) => ReactElement

export const UserContext = createContext<TUserProviderProps>({
  firstName: '',
  lastName: '',
  email: '',
  setUserDetails: noop,
})
export const useUserContext = () => useContext(UserContext)

export const UserProvider: TUserProvider = ({ children }) => {
  const { firstName, lastName, email } = useUserContext()
  const [userDetails, setUserDetails] = useState({ firstName, lastName, email })

  return (
    <UserContext.Provider
      value={{
        ...userDetails,
        setUserDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
