import { createContext, useContext, useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import * as client from '@fe/services/allplants'
import { Cart, CartUpdateRequest } from '@fe/types/allplants'
import { formatCartResponse } from '@fe/utils/cart'

type CartStatus = 'complete' | 'fetching' | 'none' | 'init'
const context = createContext<{
  cart: Cart | undefined
  status: CartStatus
  getCart: (token: string) => Promise<Cart>
  updateCart: (token: string, changes: CartUpdateRequest) => Promise<Cart>
}>(undefined as any)

export const Provider = ({ children }) => {
  const { query } = useRouter()
  const [cart, setCart] = useState<Cart | undefined>(undefined)
  const [status, setStatus] = useState<CartStatus>('init')

  const fetchCart = async (token) => {
    const [cartResponse, productsResponse] = await Promise.all([
      client.getCart(token),
      client.getProducts(),
    ])
    const cartData = cartResponse.data.data?.[0]
    const productsData = productsResponse.data.data
    if (cartData && productsData) {
      return formatCartResponse(cartData, productsData)
    }
    return null
  }

  const getCart = async (token) => {
    const newCart = await fetchCart(token)
    setCart(newCart!)
    return newCart!
  }

  const updateCart = async (token, changes) => {
    await client.updateCart(token, changes)
    const newCart = await fetchCart(token)
    setCart(newCart!)
    return newCart!
  }

  useEffect(() => {
    if (query.token) {
      void fetchCart(query.token)
        .then((newCart) => {
          if (newCart) {
            setCart(newCart)
            setStatus('complete')
          } else {
            setStatus('none')
          }
        })
        .catch((err) => {
          const coreResponse = err.response?.data?.errors?.[0]
          if (coreResponse) {
            if ((coreResponse.message as string).includes('404'))
              return setStatus('none')
            if ((coreResponse.message as string).includes('Invalid Cart token'))
              return setStatus('none')
          }
          throw err
        })
    }
  }, [query.token])

  return (
    <context.Provider
      value={{
        status,
        cart,
        getCart,
        updateCart,
      }}
    >
      {children}
    </context.Provider>
  )
}

export default function useCart() {
  const cart = useContext(context)
  if (!cart) throw new Error('Cannot get cart outside of the cart provider')
  return cart
}
