import * as React from 'react'

export default function Instagram(props) {
  return (
    <svg
      {...props}
      version='1.1'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>icon/social/instagram</title>
      <g
        fill='none'
        fillRule='evenodd'
        id='Homepage'
        stroke='none'
        strokeWidth='1'
      >
        <g
          fill='currentColor'
          id='icon/social/instagram'
          transform='translate(-8.000000, -8.000000)'
        >
          <path
            d='M17.4090909,8 C12.2443185,8 8,12.2386371 8,17.4090909 L8,30.5909091 C8,35.7556815 12.2386371,40 17.4090909,40 L30.5909091,40 C35.7556815,40 40,35.7613644 40,30.5909091 L40,17.4090909 C40,12.2443185 35.7613644,8 30.5909091,8 L17.4090909,8 Z M17.4090909,10.9090909 L30.5909091,10.9090909 C34.1874996,10.9090909 37.0909091,13.8125004 37.0909091,17.4090909 L37.0909091,30.5909091 C37.0909091,34.1874996 34.1874996,37.0909091 30.5909091,37.0909091 L17.4090909,37.0909091 C13.8125004,37.0909091 10.9090909,34.1874996 10.9090909,30.5909091 L10.9090909,17.4090909 C10.9090909,13.8125004 13.8125004,10.9090909 17.4090909,10.9090909 Z M32.5909091,14.0909091 C31.8579549,14.0909091 31.2727273,14.6761367 31.2727273,15.4090909 C31.2727273,16.1420451 31.8579549,16.7272727 32.5909091,16.7272727 C33.3238633,16.7272727 33.9090909,16.1420451 33.9090909,15.4090909 C33.9090909,14.6761367 33.3238633,14.0909091 32.5909091,14.0909091 Z M24,15.2727273 C19.198864,15.2727273 15.2727273,19.198864 15.2727273,24 C15.2727273,28.801136 19.198864,32.7272727 24,32.7272727 C28.801136,32.7272727 32.7272727,28.801136 32.7272727,24 C32.7272727,19.198864 28.801136,15.2727273 24,15.2727273 Z M24,18.1818182 C27.2329542,18.1818182 29.8181818,20.7670458 29.8181818,24 C29.8181818,27.2329542 27.2329542,29.8181818 24,29.8181818 C20.7670458,29.8181818 18.1818182,27.2329542 18.1818182,24 C18.1818182,20.7670458 20.7670458,18.1818182 24,18.1818182 Z'
            id='path-1'
          />
        </g>
      </g>
    </svg>
  )
}
